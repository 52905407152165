import {Component} from '@angular/core';
import {APPLICATION_DEFAULT_LANGUAGE} from "./app.constants";
import {TranslateService} from "@ngx-translate/core";
import {StateManagerService} from "../core/states/state-manager.service";
import {EventManagerService} from "source-ui-commons";
import {StateService} from "../core/states/state.service";
import {App} from "../core/states/app/app.actions";
import {Platform} from "@ionic/angular";
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {Capacitor} from "@capacitor/core";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public eventManager: EventManagerService,
              public stateManager: StateManagerService,
              private state: StateService,
              private platform: Platform,
              private screenOrientation: ScreenOrientation,
              private translate: TranslateService) {
    this.loadLanguage();
    platform.ready().then(() => {
      if(Capacitor.getPlatform() !== 'web') {
        this.screenOrientation.lock('portrait');
      }
      this.initStatesData();
    });
  }

  loadLanguage() {
    this.translate.setDefaultLang(APPLICATION_DEFAULT_LANGUAGE);
  }

  initStatesData() {
    this.state.init().then(() => {
      this.eventManager.publishRx(new App.InitializeApp()).subscribe();
    });
  }

}
