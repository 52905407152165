import {NavStateModel} from "./nav/nav.model";
import {StorageService} from "../../services/storage/storage.service";
import {EventManagerService} from "source-ui-commons";
import {Queue} from "../../utils/queue/queue";
import {QueueIdleTimeoutImpl} from "../../utils/queue/queue-idle-timeout.impl";
import {EmptyReference} from "./state.service";
import {PlatformStateModel} from "./platform/platform.model";
import {KeyboardStateModel} from "./keyboard/keyboard.model";
import {CameraStateModel} from "./camera/camera.model";
import {HistoryStateModel} from "./history/history-state.model";
import {ThemeStateModel} from "./theme/theme.model";
import {DEFAULT_THEME_OVERRIDES} from "../../services/theme/theme.service";
import {ProductStateModel} from "./products/product.model";


export interface AppStateModel {
  nav: NavStateModel;
  keyboard: KeyboardStateModel;
  platform: PlatformStateModel;
  camera: CameraStateModel;
  history: HistoryStateModel;
  product: ProductStateModel;
}

export class SingletonStateStorageService {
  public appState: AppStateModel;

  public nav: NavStateModel;
  public keyboard: KeyboardStateModel;
  public platform: PlatformStateModel;
  public camera: CameraStateModel;
  public history: HistoryStateModel;
  public theme: ThemeStateModel;
  public product: ProductStateModel;

  //Queue map to update LocalStorage
  private storageQueueMap: Map<string, Queue<any>> = new Map<string, QueueIdleTimeoutImpl<any>>();

  constructor(public storage: StorageService,
              public eventManager: EventManagerService) {
  }

  async init() {
    await this.storage.create();
    await this.loadDefaultValues();
    this.updateAppState();
  }

  private async loadDefaultValues() {

    this.nav = {
      kind: 'NavStateModel',
      db: false,
      active: null,
      sourceModule: null,
      pending: null,
      hyperlinking: false,
      navParams: new Map<String, any>(),
    };
    this.keyboard = {
      kind: 'KeyboardStateModel',
      db: false,
      isOpen: false
    };
    this.platform = {
      kind: 'PlatformStateModel',
      db: true,
      timeOffset: 0,
      deviceInfo: {
        uuid: null,
        model: null,
        platform: '',
        capacitor: false,
        mobile: false,
        mobileWeb: true,
        version: null,
        manufacturer: null,
        isVirtual: null,
        serial: null
      },
      isNative: false,
    };
    this.platform = await this.loadSavedValues(this.platform);

    this.camera = {
      kind: 'CameraStateModel',
      db: true,
      flashEnable: false,
      showCamera: false,
      singleShot: false,
      tags: [],
      deviceHasCamera: true,
      readType: []
    };
    this.camera = await this.loadSavedValues(this.camera);

    this.history = {
      kind: 'HistoryStateModel',
      db: true,
      data: []
    };
    this.history = await this.loadSavedValues(this.history);

    this.theme = {
      kind: 'ThemeStateModel',
      db: true,
      prefersDarkSystem: false,
      prefersDarkUser: undefined,
      baseThemeOverrides: DEFAULT_THEME_OVERRIDES,
      currentThemeOverrides: DEFAULT_THEME_OVERRIDES,
      availableBrands: [],
      selectedBrand: undefined,
      availableZoomLevels: [],
      selectedZoomLevel: undefined
    }

    this.theme = await this.loadSavedValues(this.theme);

    this.product = {
      kind: 'ProductStateModel',
      db: false,
      products: new Map(),
      unknown: new Set(),
      pictures: [],
      queue: new Set(),
      timeout: null,
      busy: {
        get: false,
        picture: false
      }
    };
  }


  private async loadSavedValues(defaultState: any): Promise<any> {
    if (defaultState.hasOwnProperty('kind') && defaultState.hasOwnProperty('db')) {
      const savedOldStorage = this.storage.getLocalStorage(defaultState.kind); //get old storage
      const savedState = await this.getDataEncryptedLocalStorage(defaultState.kind);
      try {
        if (savedState) {
          defaultState = this.setStateData(defaultState, savedState);
          if(savedOldStorage) {//merge old storage with new storage
            defaultState = {
              ...defaultState,
              ...savedOldStorage
            }
            this.storage.removeLocalStorage(defaultState.kind);
          }
        } else if(savedOldStorage) {
          defaultState = this.setStateData(defaultState, savedOldStorage);//merge old storage with new storage
          this.storage.removeLocalStorage(defaultState.kind);
        }
      } catch (e) {
        console.log('STORAGE MIGRATION FAILED IN: ', defaultState.kind, e)
      }
    }
    return defaultState;
  }

  private setStateData(defaultState, savedState: any) {
    let stateToSave = savedState;
    stateToSave.kind = defaultState.kind;
    stateToSave.db = defaultState.db;
    return stateToSave;
  }

  private async getDataEncryptedLocalStorage(key): Promise<any> {
    return await this.storage.get(key)
  }

  /**
   * Save in local storage
   * @param newState
   * @param changesPath
   */
  protected saveInLocalStorage(newState: any, changesPath: string[]) {
    if (newState.hasOwnProperty('kind') && newState.kind) {
      this.getQueue(newState.kind).add({state: {...newState}, changesPath: [...changesPath]});//Add items to storage queue
    }
  }

  /*protected saveInLocalStorage(newState: any) {
    if (newState.hasOwnProperty('kind') && newState.kind) {
      this.getQueue(newState.kind).add(newState);//Add items to storage queue
    }
  }*/

  protected updateAppState() {
    this.appState = {
      nav: this.nav,
      keyboard: this.keyboard,
      camera: this.camera,
      platform: this.platform,
      history: this.history,
      product: this.product
    };
  }

  /**
   * Handle save state in storage by queue
   * @param kind
   */
  private getQueue(kind: string): Queue<{state: any, changesPath: string[]}> {
    if (this.storageQueueMap.has(kind)) {
      return this.storageQueueMap.get(kind);
    } else {
      //TODO Just add autoExecuteEnabled: true to compile, review if is correct
      const queue = new QueueIdleTimeoutImpl<any>({name: "State Queue", maxCapacity: -1, maxTimeout: 1000, autoExecuteEnabled: true}, (newStatesObj: Array<{ state: any, changesPath: string[] }>) => {
        //Convert to Map --> Only apply the latest
        const kind = newStatesObj[0].state.kind;
        const newStateChangesMap: Map<string, any> = new Map();
        for (let newState of newStatesObj) {
          newState.changesPath.forEach(changesPath => {
            const changesPathArr = changesPath.split(".");
            changesPathArr.shift();//Remove first element
            newStateChangesMap.set(changesPath, newState.state);
          });
        }
        this.getDataEncryptedLocalStorage(kind).then((stateToUpdate) => {
          newStateChangesMap.forEach((stateValue: any, changesPath: string) => {
            const changesPathArr = changesPath.split(".");
            changesPathArr.shift();//Remove first element
            if (!stateToUpdate) {
              stateToUpdate = {...stateValue};
            } else {
              const stateCopy = {...stateToUpdate};
              stateToUpdate = this.setValueToPathRecursive(
                stateCopy,
                this.getChangedValueRecursive(stateValue, [...changesPathArr]),
                [...changesPathArr],
                stateCopy);
            }
          });
          this.saveDataEncryptedLocalStorage(kind, stateToUpdate);
        });
      });
      this.storageQueueMap.set(kind, queue);
      return queue;
    }
  }

  getChangedValueRecursive(value: any, propertyPaths: Array<string>): any {
    if (propertyPaths.length) {
      if (value && value.hasOwnProperty(propertyPaths[0])) {
        const propertyName = propertyPaths[0];
        propertyPaths.shift();
        return this.getChangedValueRecursive(value[propertyName], propertyPaths);
      } else {
        return new EmptyReference(propertyPaths[0]);
      }
    } else {
      return value;
    }
  }

  setValueToPathRecursive(obj: any, value: any, propertyPaths: Array<string>, originalObj?: any): any {
    if (!obj) {
      return undefined;
    }
    if (propertyPaths.length) {
      if (propertyPaths.length === 1) {
        (obj || {})[propertyPaths[0]] = value;
        return originalObj;
      }
      const propertyName = propertyPaths[0];
      propertyPaths.shift();
      if(!obj.hasOwnProperty(propertyName)) {
        obj[propertyName] = {};
      }
      return this.setValueToPathRecursive(obj[propertyName], value, propertyPaths, originalObj);
    }
    return originalObj;
  }

  private saveDataEncryptedLocalStorage(stateKind, stateUpdated) {
    this.storage.set(stateKind, stateUpdated).then();
  }
}
