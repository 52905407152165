import {EventModel, RxEvent} from "source-ui-commons";


export namespace App {
  export class InitializeApp extends RxEvent<any> {
    override type = "[App] Initialize App";
  }

  export class ReloadApp extends RxEvent<any> {
    override type = "[App] Reload App";
    constructor(public redirect: boolean = true) {
      super();
    }
  }

  export class ResetApp extends RxEvent<any> {
    override type = "[App] Reset App";

    constructor(public redirect: boolean = true) {
      super();
    }
  }

  export class LogoutApp extends RxEvent<any> {
    override type = "[App] Close App";

    constructor(public redirect: boolean = true) {
      super();
    }
  }

  export class PermissionsReady extends EventModel {
    override type = '[App] Permissions Ready';
  }

  export class DeviceInfoReady extends EventModel {
    override type = '[App] DeviceInfo Ready';
  }

  export class SQLiteReady extends EventModel {
    override type = '[App] SQLite Ready';
  }

  export class StatesReady extends EventModel {
    override type = '[App] States Ready';
  }

  export class LangReady extends EventModel {
    override type = '[App] Lang Ready';
  }

  export class ReaderLibReady extends EventModel {
    override type = '[App] Reader Lib Ready';
  }

  export class AppReady extends EventModel {
    override type = '[App] App Ready';
  }

  export class AppReset extends EventModel {
    override type = '[App] App reset';
  }

  export class AppLogout extends EventModel {
    override type = '[App] App logout';
  }

  export class AppReload extends EventModel {
    override type = '[App] App reload';
  }
}


