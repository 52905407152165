import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StateService} from "../../../core/states/state.service";
import {ProductImageViewport} from "../../../app/pipes/product-image/product-image";


export class ProductsClient {
  constructor(public http: HttpClient, public state: StateService) {
  }

  getProductImage(headers: HttpHeaders, path: string,): Observable<Blob> {
    return this.http.get(
      path,
      {headers: headers, responseType: 'blob'});
  }

  getProductImageByGenericUrl(url: string): Observable<Blob> {
    return this.http.get(url, {responseType: 'blob'});
  }
}
