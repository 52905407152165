import {Directive, ElementRef, OnDestroy, OnInit} from "@angular/core";
import {SubSink} from "subsink";
import {StateService} from "../../../core/states/state.service";

@Directive({
  selector: '[hide-with-keyboard]',
  standalone: true
})
export class HideWithKeyboardDirective implements OnInit, OnDestroy {

  private sub = new SubSink();

  constructor(private element: ElementRef,
              private state: StateService) {
  }

  ngOnInit() {
    this.sub.sink = this.state.observeChanges('keyboard.isOpen').subscribe({
      next: open => open ? $(this.element.nativeElement).hide() : $(this.element.nativeElement).show()
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
