<!--<ion-tab-bar slot="bottom">-->
<!--  <div class="container-buttons">-->
<!--    <ion-tab-button tab="home" (click)="navigate('history')" [ngClass]="(currentRoute === 'history') ? 'active-tab': ''">-->
<!--      <generic-icon [iconVar]="'&#45;&#45;x-icon-ship'"></generic-icon>-->
<!--    </ion-tab-button>-->
<!--    <ion-tab-button tab="scan" (click)="navigate('scan')" [ngClass]="(currentRoute === 'scan') ? 'active-tab': ''">-->
<!--      <generic-icon [iconVar]="'&#45;&#45;x-icon-ship'"></generic-icon>-->
<!--    </ion-tab-button>-->
<!--    <ion-tab-button tab="history" (click)="navigate('home')" [ngClass]="(currentRoute === 'home') ? 'active-tab': ''">-->
<!--      <generic-icon [iconVar]="'&#45;&#45;x-icon-ship'"></generic-icon>-->
<!--    </ion-tab-button>-->
<!--  </div>-->
<!--</ion-tab-bar>-->
<ion-router-outlet id="main-content"></ion-router-outlet>
