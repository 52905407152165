import {Injectable} from "@angular/core";
import {NavState} from "./nav/nav.state";
import {LoadingState} from "./loading/loading.state";
import {AppState} from "./app/app.state";
import {ModalState} from "./modal/modal.state";
import {AlertState} from "./alert/alert.state";
import {PlatformState} from "./platform/platform.state";
import {ToastState} from "./toast/toast.state";
import {KeyboardState} from "./keyboard/keyboard.state";
import {CameraState} from "./camera/camera.state";
import {HistoryState} from "./history/history.state";
import {ThemeState} from "./theme/theme.state";
import {ProductState} from "./products/product.state";

@Injectable()
export class StateManagerService {
  constructor(private appState: AppState,
              private navState: NavState,
              private loadingState: LoadingState,
              private modalState: ModalState,
              private alertState: AlertState,
              private platformState: PlatformState,
              private toastState: ToastState,
              private keyboardState: KeyboardState,
              private cameraState: CameraState,
              private historyState: HistoryState,
              private themeState: ThemeState,
              private productState: ProductState
              ) {
  }
}
