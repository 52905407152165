import {Injectable} from "@angular/core";
import {LoadingController} from "@ionic/angular";
import {BackButtonService} from './back-button.service';
import {BACK_BUTTON_PRIORITY} from "./navigation/nav.constants";

@Injectable()
export class LoadingService {

  private unregisterBackFunc: (() => void) | undefined;
  private loading: HTMLIonLoadingElement | undefined = undefined;
  private loadingWillHide = false;
  private DEFAULTS = {
    showBackdrop: true,
    enableBackdropDismiss: false,
    dismissOnPageChange: false,
    duration: 0
  };

  constructor(private loadingCtrl: LoadingController,
              private backButton: BackButtonService) {
  }

  async showLoading() {
    if (!this.loading || this.loadingWillHide) {
      this.loading = await this.loadingCtrl.create({
        ...this.DEFAULTS,
        message: 'Loading...',
        backdropDismiss: false
      });
      this.lockBackButton();
      this.loading.onWillDismiss().then(() => {
        this.unlockBackButton();
        this.loading = undefined;
        this.loadingWillHide = false;
      });
      this.loading.present().then();
    }
  }

  async hideLoading() {
    if (this.loading) {
      this.loadingWillHide = true;
      await this.loading.dismiss();
    }
    this.unlockBackButton();
  }

  lockBackButton() {
    this.unlockBackButton();
    this.unregisterBackFunc = this.backButton.registerBackButtonAction(() => {
    }, BACK_BUTTON_PRIORITY.MAX);
  }

  unlockBackButton() {
    if (this.unregisterBackFunc) {
      this.unregisterBackFunc();
      this.unregisterBackFunc = undefined;
    }
  }
}
