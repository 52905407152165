import {Injectable} from "@angular/core";
import {of, Subject} from "rxjs";
import {delay, filter} from "rxjs/operators";
import {createAnimation, NavController} from '@ionic/angular';
import {BACK_BUTTON_PRIORITY} from "./nav.constants";
import {NavigationOptions} from "@ionic/angular/common/providers/nav-controller";
import {ActivatedRoute, ActivationStart, Router} from "@angular/router";
import {AnimationUtils} from "../../utils/animation.utils";
import {BackButtonService} from "../back-button.service";
import {LoadingState} from "../../core/states/loading/loading.state";

@Injectable()
export class NavService {

  safeFirstLevelModules = [
    "LoginPage",
    "MenuPage",
    "AppConfigPage"
  ];

  activeComponentStack = [];

  isPagePopping: boolean = false;

  animationUtils: AnimationUtils = new AnimationUtils()

  $currentComponent = new Subject<any>();

  constructor(private loadingState: LoadingState,
              private backButton: BackButtonService,
              private navCtrl: NavController,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.backButton.registerBackButtonAction(() => {
      this.popCurrentPage();
    }, BACK_BUTTON_PRIORITY.PAGE);

    // Logger
    this.router.events.subscribe(event => console.log('[NavService]', event));

    // Update active component
    this.router.events.pipe(
      filter(event => event instanceof ActivationStart && !!event.snapshot.component)
    ).subscribe((event: ActivationStart) => this.$currentComponent.next(event.snapshot.component));
  }

  private getCurrentRoute() {
    // return this.route.snapshot.paramMap.get('id');
  }

  canSafelyCloseProcess(instance: any): boolean {
    let canSafelyCloseProcess;
    try {
      canSafelyCloseProcess =
        !!instance.__proto__.safelyCloseProcessAndWait ||
        !!instance.__proto__.__proto__.safelyCloseProcessAndWait ||
        !!instance.__proto__.__proto__.__proto__.safelyCloseProcessAndWait; // Check for super classes
    } catch (e) {
      canSafelyCloseProcess = false;
    }
    return canSafelyCloseProcess;
  }

  async safePushPage(page: any, params?: any) {
    const launchPage = async () => {
      await of(true).pipe(delay(200)).toPromise();
      const relativeRoute = this.router.createUrlTree([page], {
        relativeTo: this.activatedRoute
      });
      await this.navCtrl.navigateForward(relativeRoute, params);
    };

    const topInstance = this.getCurrentPageInstance();
    let canSafelyCloseProcess = this.canSafelyCloseProcess(topInstance);
    let isLoadingShowing = this.loadingState.isLoadingCreating;

    if (isLoadingShowing) {
      await Promise.reject('Loading is in progress');
    }

    if (!canSafelyCloseProcess) {
      await launchPage();
    } else {
      await topInstance.safelyCloseProcessAndWait();
      await launchPage();
    }
  }

  async pushPage(pageRoute: string, params?: any, options?: NavigationOptions) {
    if (!!params) {
      if (options) {
        options.queryParams = params;
      } else {
        options = {
          queryParams: params
        }
      }
    }
    if (!options) {
      options = {}
    }
    options.animation = (baseEl, opts) => {
      const enteringScaffold = $('app-scaffold', opts.enteringEl);
      const entering = {
        scaffold: enteringScaffold,
        header: $('.header-container', enteringScaffold[0]),
        content: $('.content-background', enteringScaffold[0]),
        footer: $('.footer-background', enteringScaffold[0])
      }
      const leavingScaffold = $('app-scaffold', opts.leavingEl);
      const leaving = {
        scaffold: leavingScaffold,
        header: $('.header-container', leavingScaffold[0]),
        content: $('.content-background', leavingScaffold[0]),
        footer: $('.footer-background', leavingScaffold[0])
      }
      if (opts.direction === 'forward') {
        $(opts.enteringEl).removeClass('ion-page-invisible');
        const enteringHeaderAnimation = createAnimation()
          .addElement(entering.header[0])
          .fromTo('opacity', '0', '1')
          .easing(this.animationUtils.EASING_CUBIC)
          .duration(this.animationUtils.DURATION_S);
        const enteringFooterAnimation = createAnimation()
          .addElement(entering.footer[0])
          .fromTo('transform', 'translateY(100%)', 'translateY(0%)')
          .easing(this.animationUtils.EASING_CUBIC)
          .duration(this.animationUtils.DURATION_S);
        const enteringContentAnimation = createAnimation()
          .addElement(entering.content[0])
          .fromTo('opacity', '0', '1')
          .fromTo('transform', 'translateY(50px)', 'translateY(0px)')
          .easing(this.animationUtils.EASING_CUBIC)
          .duration(this.animationUtils.DURATION_M);
        const leavingContentAnimation = createAnimation()
          .addElement(leaving.content[0])
          .fromTo('opacity', '1', '0')
          .fromTo('transform', 'translateY(0px)', 'translateY(100px)')
          .fromTo('transform', 'scale(1)', 'scale(0.95)')
          .easing(this.animationUtils.EASING_CUBIC)
          .duration(this.animationUtils.DURATION_S);
        return createAnimation()
          .addAnimation(enteringHeaderAnimation)
          .addAnimation(enteringContentAnimation)
          .addAnimation(enteringFooterAnimation)
          .addAnimation(leavingContentAnimation)
      } else {
        $(opts.enteringEl).removeClass('ion-page-invisible');
        const leavingHeaderFooterAnimation = createAnimation()
          .addElement(leaving.header[0])
          .addElement(leaving.footer[0])
          .fromTo('opacity', '1', '0')
          .easing(this.animationUtils.EASING_CUBIC)
          .duration(this.animationUtils.DURATION_S);
        const leavingContentAnimation = createAnimation()
          .addElement(leaving.content[0])
          .fromTo('opacity', '1', '0')
          .fromTo('transform', 'translateY(0px)', 'translateY(100px)')
          .fromTo('transform', 'scale(1)', 'scale(0.95)')
          .easing(this.animationUtils.EASING_CUBIC)
          .duration(this.animationUtils.DURATION_S);
        return createAnimation()
          .addAnimation(leavingContentAnimation)
          .addAnimation(leavingHeaderFooterAnimation)
      }
    }
    await this.navCtrl.navigateForward(pageRoute, options);
  }

  async pushRootPage(pageRoute: any, params?: any, options?: NavigationOptions) {
    if (!!params) {
      if (options) {
        options.queryParams = params;
      } else {
        options = {
          queryParams: params
        }
      }
    }
    await this.navCtrl.navigateRoot(pageRoute, options);
  }

  async popLast(page: any, params: any, options: NavigationOptions) {
    const last: any = this.getCurrentPageInstance();
    if (last instanceof HTMLIonModalElement) {
      await last.dismiss();
      return;
    }
    if (last instanceof HTMLIonPopoverElement) {
      await last.dismiss();
      return;
    }
  }

  async popCurrentPage(options?: NavigationOptions, route?: string) {
    if (this.isPagePopping) {
      return;
    }
    const topInstance = this.getCurrentPageInstance();
    const popPage = async () => {
      if (route || topInstance.backActionReturnRoute) {
        await this.navCtrl.navigateBack(route || topInstance.backActionReturnRoute, options);
      } else {
        if (options) {
          await this.navCtrl.back(options);
        } else {
          await this.navCtrl.pop();
        }
      }
    };
    await topInstance.askLeavePage();
    this.isPagePopping = true;
    await popPage();
    this.isPagePopping = false;
  }

  async popUnsafeCurrentPage(options?: NavigationOptions) {
    if (this.isPagePopping) {
      return;
    }
    const popPage = async () => {
      if (options) {
        await this.navCtrl.back(options);
      } else {
        await this.navCtrl.pop();
      }
    };
    this.isPagePopping = true;
    await popPage();
    this.isPagePopping = false;
  }

  isCurrentPageSafeToPushNewPage(): boolean {
    return this.safeFirstLevelModules.some(m => m === this.getCurrentPageName());
  }

  isCurrentPageBeforeInitialization() {
    return false;
  }

  getCurrentPageName(): string {
    return this.getCurrentPageInstance().constructor.name;
  }

  getCurrentPageInstance(): any {
    return this.activeComponentStack[this.activeComponentStack.length - 1];
  }

  onRouteActivated(activatedComponent: any) {
    this.activeComponentStack.push(activatedComponent);
  }

  onRouteDeactivated(activatedComponent: any) {
    this.activeComponentStack = this.activeComponentStack.filter(comp => comp !== activatedComponent);
  }

  /**
   * Returns whether the component (Page, Modal, Popover, etc) is on top or not using the activeNav
   * @param component must be a string (WARNING, do not use SomeModal.name as parameter as it imports the modal
   * on the code and the App it may have issues to run, instead use 'SomeModal' as string)
   * TODO: REFACTOR!! MOVE TO OTHER PLACE, AS NAVSERVICE ONLY HANDLES PAGES!! NOT POPOVERS OR MODALS
   */
  isComponentOnTop(component: string): boolean {
    return this.activeComponentStack && this.activeComponentStack.constructor.name === component;
  }

  /**
   * Returns whether the page is on top or not using the rootNav (base page navigation)
   * @param page must be a string (WARNING, do not use SomePage.name as parameter as it imports the page
   * on the code and the App it may have issues to run, instead use 'SomePage' as string)
   */
  isPageOnTop(page: string): boolean {
    return this.activeComponentStack.length && this.getCurrentPageName() === page;
  }
}
