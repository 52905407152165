import {Platform} from '@ionic/angular';
import {EventModel, RxEvent} from "source-ui-commons";

export namespace PlatformNamespace {
  export class PlatformReady extends EventModel {
    override type = '[Platform] Ready';

    constructor(public platform: Platform) {
      super();
    }
  }

  export class PlatformPaused extends EventModel {
    override type = '[Platform] Platform paused';
  }

  export class PlatformResumed extends EventModel {
    override type = '[Platform] Platform resumed';
  }

  export class PlatformResized extends EventModel {
    override type = '[Platform] Resize resized';
  }

  export class SetConfigurationFilePath extends EventModel {
    override type = '[Platform] App set configuration file path';
  }

  export class InitializeDeviceInfo extends RxEvent<any> {
    override type = '[Platform] Initialize Device Info'
  }

}
