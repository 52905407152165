import {NgZone, Pipe, PipeTransform} from '@angular/core';
import {EMPTY, Observable} from "rxjs";

@Pipe({
  name: 'withNgZone',
})
export class WithNgZonePipe implements PipeTransform {

  constructor(private ngZone: NgZone) {
  }

  /**
   * Forces the observable to run each push of data into the specified ngzone, to force trigger change detection
   * @param ngZone
   */
  runOnNgZone<T>(ngZone: NgZone) {
    return function patchedObservable(source: Observable<T>): Observable<T> {
      return new Observable(observer => {
        const onNext = (value: T) => ngZone.run(() => observer.next(value));
        const onError = (e) => ngZone.run(() => observer.error(e));
        const onComplete = () => ngZone.run(() => observer.complete());
        return source.subscribe(onNext, onError, onComplete);
      });
    };
  }

  transform<T>(value: Observable<T>): Observable<T> {
    return !value ? EMPTY: value.pipe(this.runOnNgZone(this.ngZone));
  }
}
