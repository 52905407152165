import {SingletonStateStorageService} from "./singleton-state-storage.service";
import {Subject} from "rxjs";
import {StorageService} from "../../services/storage/storage.service";
import {EventManagerService} from "source-ui-commons";


export class SingletonStateSelectorsService extends SingletonStateStorageService {

  //Key: Path, Value: subject
  protected subjectMap: Map<string, Subject<any>> = new Map<string, Subject<any>>();

  constructor(public override storage: StorageService,
              public override eventManager: EventManagerService) {
    super(storage, eventManager);
  }
}
