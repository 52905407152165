import {Injectable} from '@angular/core';
import {ProductImageViewport} from "../../../app/pipes/product-image/product-image";

@Injectable()
export class ProductsCacheService {

  private productImageMapMobileViewport: Map<string, Blob> = new Map<string, Blob>();
  private productImageMapDesktopViewport: Map<string, Blob> = new Map<string, Blob>();
  private _productsNotFound: string[] = [];


  saveImage(gtin: string, image: Blob, viewport: ProductImageViewport) {
    if (image) {
      if (viewport === 'desktop') {
        this.productImageMapDesktopViewport.set(gtin, image);
      } else {
        this.productImageMapMobileViewport.set(gtin, image);
      }
      this._productsNotFound.forEach((item, index) => {
        if(item === gtin){
          this._productsNotFound.splice(index, 1);
          return;
        }
      });
    }
  }

  getImageCacheByGtin(gtin: string, viewport: ProductImageViewport): Blob {
    if (viewport === 'desktop') {
      return this.productImageMapDesktopViewport.get(gtin);
    } else {
      return this.productImageMapMobileViewport.get(gtin);
    }
  }

  hasImage(gtin: string, viewport: ProductImageViewport): boolean {
    if (viewport === 'desktop') {
      return this.productImageMapDesktopViewport.has(gtin);
    } else {
      return this.productImageMapMobileViewport.has(gtin);
    }
  }

  addNotFoundProduct(gtin: string){
    this._productsNotFound.push(gtin);
  }
  get productsNotFound(): string[]{
    return this._productsNotFound;
  }
}
