export const BACK_BUTTON_PRIORITY = {
  MIN: 0,
  PAGE: 1,
  MODAL: 2,
  CAMERA: 3,
  POPOVER: 4,
  ALERT: 5,
  MAX: 10,
  TOP: 1000
}
