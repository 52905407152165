import {Injectable} from "@angular/core";
import {AppTheme, ThemeBrand, ThemeVariables, ZoomLevel} from "./theme.model";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {DEFAULT_THEME_OVERRIDES, ThemeService} from "../../../services/theme/theme.service";
import {EventManagerService} from "source-ui-commons";
import {StateService} from "../state.service";
import {Theme} from "./theme.actions";
import SetThemeByBrandName = Theme.SetThemeByBrandName;
import ResetTheme = Theme.ResetTheme;
import {App} from "../app/app.actions";
import UpdateAvailableThemes = Theme.UpdateAvailableThemes;
import SetZoomLevel = Theme.SetZoomLevel;
import ReduceZoomLevel = Theme.ReduceZoomLevel;
import IncreaseZoomLevel = Theme.IncreaseZoomLevel;
import {ThemeUtils} from "../../../services/theme/theme.utils";

export const DEFAULT_ZOOM_LEVEL: ZoomLevel = {
  label: 'global.default',
  cssValue: '1'
}

@Injectable()
export class ThemeState {

  private prefersDark: boolean = false;

  constructor(private eventManager: EventManagerService,
              private state: StateService,
              private themeService: ThemeService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(SetThemeByBrandName.getName()).subscribe(this.setThemeByBrandName);
    this.eventManager.subscribe(ResetTheme.getName()).subscribe(this.resetTheme);
    this.eventManager.subscribe(App.StatesReady.getName()).subscribe(this.initTheme);
    this.eventManager.subscribeRx(UpdateAvailableThemes.getName(), this.updateThemeVariables);
    this.eventManager.subscribe(SetZoomLevel.getName()).subscribe(this.setZoomLevel);
    this.eventManager.subscribe(ReduceZoomLevel.getName()).subscribe(this.reduceZoomLevel);
    this.eventManager.subscribe(IncreaseZoomLevel.getName()).subscribe(this.increaseZoomLevel);
  }

  updateThemeVariables = (action: UpdateAvailableThemes): Observable<ThemeVariables> => {
    return this.themeService.getThemeVariables().pipe(
      tap((newThemeVariables) => {
        const theme = {...this.state.theme};
        theme.availableBrands = newThemeVariables.themeBrands;
        theme.baseThemeOverrides = {
          variables: ThemeUtils.mergeValues(newThemeVariables.baseThemeOverrides.variables, DEFAULT_THEME_OVERRIDES.variables),
          variablesDark: ThemeUtils.mergeValues(newThemeVariables.baseThemeOverrides.variablesDark, DEFAULT_THEME_OVERRIDES.variablesDark)
        };
        let selectedThemeOverrides = theme.availableBrands.find((th) => th.name === theme.selectedBrand);
        if (!selectedThemeOverrides) {
          selectedThemeOverrides = theme.availableBrands[0];
          theme.selectedBrand = theme.availableBrands[0].name;
        }
        theme.currentThemeOverrides = {
          variables: ThemeUtils.mergeValues(selectedThemeOverrides.variables, theme.baseThemeOverrides.variables),
          variablesDark: ThemeUtils.mergeValues(selectedThemeOverrides.variablesDark, theme.baseThemeOverrides.variablesDark)
        };
        this.setThemeVariables(theme.currentThemeOverrides);
        this.state.patchState(theme, ['theme.availableBrands', 'theme.menuDefinition', 'theme.baseThemeOverrides', 'theme.currentThemeOverrides', 'theme.selectedBrand']);
      })
    );
  };

  private initTheme = () => {
    const prefersDarkQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.prefersDark = prefersDarkQuery.matches;
    this.setThemeVariablesToHtml(this.prefersDark, this.state.theme?.currentThemeOverrides || DEFAULT_THEME_OVERRIDES);
    this.setZoomVariables();
    this.setZoomLevelToBody(this.getSelectedZoomLevel());

    prefersDarkQuery.addEventListener('change', (mediaQuery) => {
      this.prefersDark = mediaQuery.matches;
      this.setThemeVariablesToHtml(this.prefersDark, this.state.theme?.currentThemeOverrides || DEFAULT_THEME_OVERRIDES);
      this.setZoomVariables();
      this.setZoomLevelToBody(this.getSelectedZoomLevel());
    });
  }

  resetTheme = (action: ResetTheme) => {
    const theme = {...this.state.theme};
    theme.currentThemeOverrides = DEFAULT_THEME_OVERRIDES;
    theme.baseThemeOverrides = DEFAULT_THEME_OVERRIDES;
    theme.availableBrands = [];
    theme.selectedBrand = undefined;
    theme.availableZoomLevels = [];
    theme.selectedZoomLevel = undefined;
    this.state.patchState(theme, ['theme']);
    this.setThemeVariables(theme.currentThemeOverrides);
  }

  private setThemeVariables(newThemeVariables: AppTheme) {
    this.setThemeVariablesToHtml(this.prefersDark, newThemeVariables);
    this.setZoomVariables();
    this.setZoomLevelToBody(this.getSelectedZoomLevel());
  }

  setThemeByBrandName = (action: SetThemeByBrandName) => {
    const theme = {...this.state.theme};
    const selectedThemeOverrides = theme.availableBrands.find((th) => th.name === action.brandName);
    const newThemeVariables: AppTheme = {
      variables: ThemeUtils.mergeValues(selectedThemeOverrides.variables, theme.baseThemeOverrides.variables),
      variablesDark: ThemeUtils.mergeValues(selectedThemeOverrides.variablesDark, theme.baseThemeOverrides.variablesDark)
    }
    theme.currentThemeOverrides = newThemeVariables;
    this.state.patchState(theme, ['theme.currentThemeOverrides']);
    this.setThemeVariables(newThemeVariables);
    this.setSelectedTheme(selectedThemeOverrides);
  }

  private setSelectedTheme(selectedThemeBrand: ThemeBrand) {
    const theme = {...this.state.theme};
    theme.selectedBrand = selectedThemeBrand.name;
    this.state.patchState(theme, ['theme.selectedBrand']);
  }

  private setZoomVariables = () => {
    const theme = {...this.state.theme};

    //Get all the values for Zoom Level
    const themeJsonVariables = theme.currentThemeOverrides?.variables || {};
    const zoomLevels: ZoomLevel[] = Object.entries(themeJsonVariables)
      .filter(([key, value]) => key.startsWith('--x-zoom-level-'))
      .map(([key, value]) => ({
        label: key.replace('--x-zoom-level-', ''),
        cssValue: value
      }));

    if (!zoomLevels.some(zoomLevel => Number(zoomLevel.cssValue) === Number('1'))) {
      zoomLevels.push(DEFAULT_ZOOM_LEVEL);
    }

    zoomLevels.sort((a, b) => Number(a.cssValue) - Number(b.cssValue));

    theme.availableZoomLevels = zoomLevels;
    theme.selectedZoomLevel = zoomLevels.find(zl => zl.label === theme.selectedZoomLevel)?.label || zoomLevels.find(zl => Number(zl.cssValue) === Number('1')).label;

    this.state.patchState(theme, ['theme.availableZoomLevels', 'theme.selectedZoomLevel']);
  }

  private setThemeVariablesToHtml = (prefersDarkMode: boolean, theme: AppTheme) => {
    const root = document.documentElement;
    const appliedStyles = $('html').attr('style')?.split('; ')?.map(style => style.split(': ')) || [];
    appliedStyles.forEach(([key, value]) => {
      root.style.removeProperty(key);
    });
    prefersDarkMode = false;
    if (prefersDarkMode) {
      if (theme?.variablesDark && Object.keys(theme.variablesDark).length) {
        Object.keys(theme.variablesDark).forEach(key => {
          root.style.removeProperty(key);
          root.style.setProperty(key, theme.variablesDark[key]);
        });
      }
    } else {
      if (theme?.variables && Object.keys(theme.variables).length) {
        Object.keys(theme.variables).forEach(key => {
          root.style.removeProperty(key);
          root.style.setProperty(key, theme.variables[key]);
        });
      }
    }
  }

  setZoomLevel = (action: SetZoomLevel) => {
    const theme = {...this.state.theme};
    const newZoomLevel = theme.availableZoomLevels.find(zl => zl.label === action.zoomLevel.label);
    if (newZoomLevel) {
      theme.selectedZoomLevel = action.zoomLevel.label;
      this.state.patchState(theme, ['theme.selectedZoomLevel']);
      this.setZoomLevelToBody(newZoomLevel);
    }
  }

  increaseZoomLevel = (action: IncreaseZoomLevel) => {
    const theme = {...this.state.theme};
    const currentIndex = theme.availableZoomLevels.findIndex(zl => zl.label === theme.selectedZoomLevel);
    if (currentIndex < theme.availableZoomLevels.length - 1) {
      const newZoomLevel = theme.availableZoomLevels[currentIndex + 1];
      theme.selectedZoomLevel = newZoomLevel.label;
      this.state.patchState(theme, ['theme.selectedZoomLevel']);
      this.setZoomLevelToBody(newZoomLevel);
    }
  }

  reduceZoomLevel = (action: ReduceZoomLevel) => {
    const theme = {...this.state.theme};
    const currentIndex = theme.availableZoomLevels.findIndex(zl => zl.label === theme.selectedZoomLevel);
    if (currentIndex > 0) {
      const newZoomLevel = theme.availableZoomLevels[currentIndex - 1];
      theme.selectedZoomLevel = newZoomLevel.label;
      this.state.patchState(theme, ['theme.selectedZoomLevel']);
      this.setZoomLevelToBody(newZoomLevel);
    }
  }

  private setZoomLevelToBody(zoomLevel: ZoomLevel = DEFAULT_ZOOM_LEVEL) {
    document.body.style['zoom'] = zoomLevel.cssValue;
  }

  private getSelectedZoomLevel(): ZoomLevel {
    return this.state.theme.availableZoomLevels.find(zl => zl.label === this.state.theme.selectedZoomLevel) || DEFAULT_ZOOM_LEVEL;
  }
}
