import {YtemAuthenticationModel} from '../model/ytemSettings';
import {NotInitializedException} from '../model/not-initialized.exception';
import {HttpHeaders} from '@angular/common/http';
import {YTEM_PLATFORM} from '../../../app.constants';

export class YtemBaseService {
  private config: YtemAuthenticationModel | undefined;

  init(config: YtemAuthenticationModel) {
    this.config = config;
  }

  protected getPath(relativePath: string) {
    if (!this.config) {
      throw new NotInitializedException();
    }
    return this.config.host + (relativePath ? relativePath : '');
  }


  protected getHost() {
    return this.config.host
  }

  protected getUser() {
    return this.config.username
  }

  protected getToken(): string {
    return this.config.token
  }

  protected getHeaders(): HttpHeaders {
    if (!this.config) {
      throw new NotInitializedException();
    }

    let headers = new HttpHeaders();
    headers = headers.set('clientid', YTEM_PLATFORM.DEFAULT_CLIENT_ID);
    if (this.config.token) {
      headers = headers.set('token', this.config.token);
    }
    if (this.config.apiKey) {
      //headers = headers.set('api_key', this.config.apiKey);
      //headers = headers.set('apiKey', this.config.apiKey);
    } else {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.config.username + ':' + this.config.password));
      headers = headers.set('Access-Control-Allow-Origin', '*');
      headers = headers.set('Accept', 'application/json');
      headers = headers.set('Content-Type', 'application/json');
    }
    return headers;
  }
}
