import {Injectable} from '@angular/core';
import {Platform} from "@ionic/angular";
import {from} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {StateService} from "../state.service";
import {EventManagerService} from "source-ui-commons";
import {DeviceInfo} from "./platform.model";
import {Device, DeviceId} from "@capacitor/device";
import {PlatformNamespace} from "./platform.actions";

export const UUID_NOT_AVAILABLE = 'UUID not available';

@Injectable()
export class PlatformState {
  constructor(private state: StateService,
              private eventManager: EventManagerService,
              private platform: Platform) {
    this.platform.resume.subscribe(() => {
      this.eventManager.publish(new PlatformNamespace.PlatformResumed());
    });
    this.platform.pause.subscribe(() => {
      this.eventManager.publish(new PlatformNamespace.PlatformPaused());
    });
    this.platform.resize.pipe(debounceTime(200)).subscribe(() => {
      this.eventManager.publish(new PlatformNamespace.PlatformResized());
    })
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribeRx(PlatformNamespace.InitializeDeviceInfo.getName(), (ev) => from(this.getDeviceInfo(ev)));
  }

  getDeviceInfo = async (action: PlatformNamespace.InitializeDeviceInfo): Promise<void> => {
    const setDeviceInfoInState = (deviceInfo: DeviceInfo) => {
      const platform = {...this.state.platform};
      platform.deviceInfo = deviceInfo;
      platform.isNative = deviceInfo.capacitor;
      this.state.patchState(platform, ['platform.deviceInfo', 'platform.isNative']);
    }

    const deviceInfoData = await Device.getInfo();

    const deviceInfo: DeviceInfo = {
      uuid: UUID_NOT_AVAILABLE,
      model: deviceInfoData.model ? deviceInfoData.model : '',
      platform: this.platform.is('android') ? 'android' :
        this.platform.is('ios') ? 'ios' :
          'web',
      mobile: this.platform.is('mobile'),
      mobileWeb: this.platform.is('mobileweb'),
      capacitor: this.platform.is('capacitor'),
      version: deviceInfoData.osVersion ? deviceInfoData.osVersion : '',
      manufacturer: deviceInfoData.manufacturer ? deviceInfoData.manufacturer : '',
      serial: deviceInfoData.manufacturer ? deviceInfoData.manufacturer : '',
      isVirtual: deviceInfoData.isVirtual ? deviceInfoData.isVirtual : false,
    };

    try {
      const uuid: DeviceId = await Device.getId();
      deviceInfo.uuid = this.platform.is('capacitor') ? '00000000-0000-0000-' + uuid.identifier.slice(0, 4) + '-' + uuid.identifier.slice(4) : uuid.identifier;
      setDeviceInfoInState(deviceInfo);
    } catch (error) {
      console.log(error);
      deviceInfo.uuid = 'web'
      setDeviceInfoInState(deviceInfo);
    }
  }

}
