import {Injectable} from "@angular/core";
import {Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
import {Observable, Observer, of, Subject} from "rxjs";
import {delay, filter, map, mapTo, switchMap, tap} from "rxjs/operators";
import {BarcodeScanner, ScanResult} from '@capacitor-community/barcode-scanner';
import {SubSink} from "subsink";
import {EventManagerService} from "source-ui-commons";
import {StateService} from "../state.service";
import {App} from "../app/app.actions";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {Camera} from "./camera.actions";
import {PlatformNamespace} from "../platform/platform.actions";

@Injectable()
export class CameraState {

  private pausedScanning = false;
  private currentCameraScanParams: Camera.CameraScanParams;
  private regexToValidate: Array<RegExp> = [];

  private scanningSub = new SubSink();

  private cameraScanSubject = new Subject<Camera.CameraScanResponse>();

  constructor(private eventManager: EventManagerService,
              private state: StateService,
              private diagnostic: Diagnostic) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(App.AppReady.getName()).subscribe(this.resetCameraState);
    this.eventManager.subscribe(Camera.EnableFlash.getName()).subscribe(this.enableFlash);
    this.eventManager.subscribe(Camera.DisableFlash.getName()).subscribe(this.disableFlash);
    this.eventManager.subscribe(PlatformNamespace.PlatformResumed.getName()).subscribe(this.getFlashStatus);
    this.eventManager.subscribeRx(Camera.ShowScanCamera.getName(), this.showScanCamera);
    this.eventManager.subscribe(Camera.HideCamera.getName()).subscribe(this.hideCamera);
    this.eventManager.subscribeRx(Camera.ReviewCamera.getName(), this.checkCameraPermissions);
    this.eventManager.subscribeRx(Camera.SubscribeCameraScan.getName(), this.subscribeCameraScan);
    this.eventManager.subscribe(Camera.RemoveTagsFromCameraQueue.getName()).subscribe(this.removeTagsFromCameraQueue)
  }

  resetCameraState = () => {
    const camera = {...this.state.camera};
    camera.flashEnable = false;
    camera.showCamera = false;
    camera.readType = [];
    camera.tags = [];
    this.state.patchState(camera, ['camera.flashEnable']);
  }

  checkCameraPermissions = (): Observable<boolean> => {
    const camera = {...this.state.camera};
    const validateCamera = (): Promise<boolean> => {
      return new Promise<boolean>((resolve) => {
        BarcodeScanner.checkPermission({force: true}).then((status) => {
          if (status.granted) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
      })
    }

    if (!this.state.platform.deviceInfo.capacitor) {
      camera.deviceHasCamera = true;
      this.state.patchState(camera, ['camera.deviceHasCamera']);
      return of(true);
    }

    return of(true).pipe(
      tap(() => {
        validateCamera().then(async (permission) => {
          if (permission) {
            camera.deviceHasCamera = await this.diagnostic.isCameraAvailable();
          } else {
            camera.deviceHasCamera = false;
            console.log('Prepare camera permissions error');
          }
          this.state.patchState(camera, ['camera.deviceHasCamera']);
          return Promise.resolve();
        })
      }),
      delay(100),
      mapTo(this.state.camera.deviceHasCamera)
    );
  }

  /**
   * Commands
   */
  getFlashStatus = (_: Camera.GetFlashStatus) => {
    if (this.state.camera?.showCamera) {
      if (this.state.camera && this.state.camera.flashEnable) {
        this.eventManager.publish(new Camera.EnableFlash());
      } else {
        this.eventManager.publish(new Camera.DisableFlash());
      }
    }
  };

  enableFlash = (_: Camera.EnableFlash) => {
    const camera = {...this.state.camera};
    camera.flashEnable = true;
    this.state.patchState(camera, ['camera.flashEnable']);
    BarcodeScanner.enableTorch();
  }

  disableFlash = (_: Camera.DisableFlash) => {
    const camera = {...this.state.camera};
    camera.flashEnable = false;
    this.state.patchState(camera, ['camera.flashEnable']);
    BarcodeScanner.disableTorch();
  }

  //TYPE READ CODE_39,CODE_128,QR_CODE,EAN_13,UPC_E,MAXICODE,CODE_93,AZTEC,CODABAR,DATA_MATRIX,EAN_8,ITF,PDF_417,RSS_14,RSS_EXPANDED,UPC_A,UPC_EAN_EXTENSION
  showScanCamera = (action: Camera.ShowScanCamera): Observable<void> => {
    const getRegex = (regex: string): RegExp => {
      switch (regex) {
        case 'GS1-128':
          return new RegExp('^(?<gtin>\\(01\\))(?<comp_code>12345678)(?<part_code>\\d{5})0?(?<pd_ed>\\((?:11|17)\\))(?<date>\\d{6})(?<bat_no>\\((?:21|10)\\))(?<data_req>\\d{1,20}?)\\b(?<count>(?:\\(30\\))?)(?<data_opt>(?:\\d{1,8})?)$')
        case 'URL':
          return new RegExp('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)')
        default :
          return new RegExp('');
      }
    }

    this.regexToValidate = [];
    if (action.scanParams.regex) {
      this.regexToValidate = action.scanParams.regex.split('|').map(reg => getRegex(reg));
    }

    return new Observable((observer: Observer<any>) => {
      BarcodeScanner.prepare({targetedFormats: action.scanParams.readtypes}).then(() => {
          observer.next(true)
      })
    }).pipe(
      switchMap(() => new Observable((observer: Observer<any>) => {
        BarcodeScanner.showBackground().then(() => {
          observer.next(true)
        })
      })),
      tap({
        next: () => {
          this.currentCameraScanParams = {...action.scanParams};
          this.pausedScanning = false;

          const camera = {...this.state.camera};
          camera.showCamera = true;
          camera.singleShot = action.scanParams.singleShot;
          camera.readType = action.scanParams.readtypes || [];
          camera.tags = action.scanParams.valuesToIgnore;
          this.state.patchState(camera, ['camera.showCamera', 'camera.singleShot', 'camera.tags', 'camera.readType']);

          if (camera.flashEnable) {
            this.eventManager.publish(new Camera.EnableFlash());
          }
          this.hideAppContent();
          this.scanningSub.sink = this.startScanAction().subscribe({
            next: data => {
              this.cameraScanSubject.next(data)
            }
          })
        },
        error: err => {
          const camera = {...this.state.camera};
          camera.showCamera = false;
          camera.readType = [];
          camera.tags = [];
          this.state.patchState(camera, ['camera.showCamera', 'camera.tags', 'camera.readType']);
        }
      })
    );
  }

  hideCamera = (_: Camera.HideCamera): Observable<void> => {
    this.currentCameraScanParams = undefined;
    this.scanningSub.unsubscribe();
    const camera = {...this.state.camera};
    camera.showCamera = false;
    camera.readType = [];
    this.state.patchState(camera, ['camera.showCamera', 'camera.readType']);
    this.showAppContent();
    return fromPromise(BarcodeScanner.stopScan());
  }

  private purgeScannedTags = () => {
    const camera = {...this.state.camera};
    camera.tags = [];
    this.state.patchState(camera, ['camera.tags']);
  }

  private addToScannedTags = (tags: string[]) => {
    const newTags = [...(this.state.camera.tags || []), ...tags];
    const camera = {...this.state.camera};
    camera.tags = newTags;
    this.state.patchState(camera, ['camera.tags']);
  }

  private startScanAction(): Observable<Camera.CameraScanResponse> {
    if (this.state.camera.singleShot) {
      return fromPromise(BarcodeScanner.startScan({targetedFormats: this.state.camera.readType})).pipe(
        filter(() => !this.pausedScanning),
        map(d => this.parseValidateData(d.content))
      );
    } else {
      const prepareScanning = (onScanFound: (data) => void,
                               onScanError: (err) => void) => BarcodeScanner.startScanning({
        targetedFormats: this.state.camera.readType
      }, (data: ScanResult, err) => err ? onScanError(err) : onScanFound(data.content));
      const pauseScanning = () => BarcodeScanner.pauseScanning();
      const resumeScanning = () => BarcodeScanner.resumeScanning();
      const multipleShotSubject = new Subject<Camera.CameraScanResponse>();
      const onData = (data: string) => {
        if (this.pausedScanning){
          return;
        }
        pauseScanning().then(async () => {
          multipleShotSubject.next(this.parseValidateData(data));
          await resumeScanning();
          return Promise.resolve();
        });
      };
      const onError = (err) => multipleShotSubject.error(err);
      return fromPromise(prepareScanning(onData, onError)).pipe(switchMap(() => multipleShotSubject));
    }
  }

  private parseValidateData(dataFound: string): Camera.CameraScanResponse {
    console.log('TAG RECEIVE', dataFound)

    // TODO: This logic must be part of the module, not in the camera state!!
    // if (this.currentCameraScanParams.scanValidateHexa) {
    //   const tagDecode = GS1Decoder.decode(this.state, "HEXA", dataFound)?.decodedData as TagDecoded;
    //   if (!tagDecode || !tagDecode.gtin) {
    //     return {tag: dataFound, message: 'invalidHexa'};
    //   }
    // }
    if (this.state.camera?.tags?.length && this.state.camera.tags.indexOf(dataFound) > -1) {
      return {tag: dataFound, message: 'duplicated'};
    }
    // TODO: This logic must be part of the module, not in the camera state!!
    // if (this.currentCameraScanParams.useToastForReads) {
    //   return;
    // }
    if (!this.currentCameraScanParams.continueReadingOnDuplicated) {
      this.pausedScanning = true;
    }
    if (this.currentCameraScanParams.regex && this.currentCameraScanParams.regex !== 'ALL') {
      let validExp = false;
      for (let i = 0; i < this.regexToValidate.length; i++) {
        if (this.regexToValidate[i].test(dataFound)) {
          validExp = true;
          break;
        }
      }
      if (!validExp) {
        return {tag: undefined, message: 'invalid'};
      }
    }
    if (this.currentCameraScanParams.singleShot) {
      this.purgeScannedTags();
    } else {
      this.addToScannedTags([dataFound]);
    }
    return {tag: dataFound, message: 'valid'};
  }

  private showAppContent() {
    const ionicRouter = $('ion-app > ion-router-outlet');
    const app = $('ion-app');
    ionicRouter.fadeIn();
    app.css('background-color', 'var(--x-color-on-surface)');
  }

  private hideAppContent() {
    const ionicRouter = $('ion-app > ion-router-outlet');
    const app = $('ion-app');
    ionicRouter.fadeOut();
    app.css('background-color', 'transparent');
  }

  private subscribeCameraScan = (): Observable<Camera.CameraScanResponse> => {
    return this.cameraScanSubject.asObservable();
  }

  private removeTagsFromCameraQueue = (action: Camera.RemoveTagsFromCameraQueue) => {
    const camera = {...this.state.camera};
    camera.tags = camera.tags.filter(t => !action.tags.some(tagToRemove => tagToRemove === t));
    this.state.patchState(camera, ['camera.tags']);
  }
}
