import {InterpolatedString} from "./interpolated.string";

export type ToastButtonRole = 'ok' | 'cancel' | 'undo' | 'retry';

export class ToastButton {
  constructor(public label: string, public role: ToastButtonRole | string) {
  }
}

export const TOAST_PRIORITY_DEFAULT = -1;
export type ToastPriority = number

export interface ToastDictionaryConfig {
  duration?: number // in ms, -1 or undefined for infinite
  buttons?: ToastButton[]
  cssClass?: string
  position?: 'top' | 'bottom'
  createOrUpdateExisting?: boolean
  priority?: ToastPriority
  onButtonClick?: (btnRole: ToastButtonRole | string) => void  // returns the button's role when clicked
}

export class ToastDictionaryItem {
  constructor(public label: string | InterpolatedString,
              public config: ToastDictionaryConfig = DEFAULT_TOAST_CONFIG) {
  }
}

/****************************************************
 ********************** DEFAULTS *********************
 ******************************************************/

export const DEFAULT_TOAST_CONFIG: ToastDictionaryConfig = {
  duration: 1300,
  buttons: [],
  createOrUpdateExisting: false,
  position: 'bottom',
  priority: TOAST_PRIORITY_DEFAULT,
  onButtonClick: undefined
}

export const DEFAULT_TOAST_BUTTONS = {
  OK: new ToastButton('global.ok', 'ok'),
  CANCEL: new ToastButton('global.cancel', 'cancel')
}

export const DEFAULT_TOASTS = {
}
