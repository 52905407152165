import {Timer} from '../timer';
import {Queue} from "./queue";

export interface QueueIdleTimeoutOptions {
  name: string;
  maxCapacity: number;
  maxTimeout: number;
  autoExecuteEnabled: boolean;
  allowMultipleReads?: boolean;
  lockQueue?: boolean;
}

/**
 * This class handles Queues of any object and executes a callback when the timeout has finished without new incoming items or has reached the maximum length
 * The maximum length can be 0 or -1 to disable it
 * The maximum timeout can be 0 or -1 to disable timeout, callbacks will arrive for each tag that was added
 * The maximum timeout is expressed in milliseconds
 */
export class QueueIdleTimeoutImpl<T> implements Queue<T>{
  private readonly defaultQueueOptions: QueueIdleTimeoutOptions = {
    name: "Default Queue",
    maxCapacity: 100,
    maxTimeout: 5000,
    autoExecuteEnabled: true,
    allowMultipleReads: false,
    lockQueue: false
  };
  private readonly options: QueueIdleTimeoutOptions;
  private readonly executor: (items: T[]) => void;
  private _items: T[] = [];
  private timer: Timer;

  constructor(options?: QueueIdleTimeoutOptions, executor?: (items: T[]) => void) {
    this.options = options || this.defaultQueueOptions;
    this.executor = executor;
  }

  /**
   * Adds a item to the queue, can be called multiple times before the execution of the callback is made
   * @param {T} item
   */
  add(item: T) {
    if (this.options.lockQueue) {
      return;
    }
    if (this._items.indexOf(item) > -1) {
      return;
    }
    this._items.push(item);
    if (this.options.autoExecuteEnabled) {
      if (this.options.allowMultipleReads) {
        this.execute();
      } else {
        const wasPaused = this.timer?.isPaused();
        if (this.options.maxCapacity > 0 && this._items.length >= this.options.maxCapacity) {
          this.execute();
        } else {
          this.cancelTimer();
          this.timer = this.createTimer();
          if (wasPaused) {
            this.timer.pause();
          }
        }
      }
    }
  }

  /**
   * Executes the queue, can be called from outside the class
   * @param {(items: T[]) => void} executorOverride can be used if you want to use other executor than the default from constructor
   */
  execute(executorOverride?: (items: T[]) => void) {
    const executeQueue = (executor: (items: T[]) => void) => {
      if (this.options.maxCapacity > 0) {
        const items = this._items.slice(0, this.options.maxCapacity);
        const remainingItems = this._items.slice(this.options.maxCapacity, this._items.length);
        if (items.length > 0) {
          executor(items);
          this._items = remainingItems;
          if (this._items.length > 0) {
            this.execute(executorOverride);
          }
        }
      } else {
        executor(this._items);
        this._items = [];
      }
    }

    if (this.timer && !this.timer.isPaused()) {
      this.cancelTimer();
    }
    if (!executorOverride && !this.executor) {
      console.error('No Executor for Queue!');
      return;
    }
    if (executorOverride) {
      executeQueue(executorOverride);
      return;
    }
    executeQueue(this.executor);
  }

  /**
   * Getter for items
   * @returns {T[]}
   */
  get items(): T[] {
    return this._items;
  }

  /**
   * Returns if the item queue is empty
   * @returns {boolean}
   */
  isEmpty(): boolean {
    return this._items.length === 0;
  }

  /**
   * Cancels the current timer
   */
  cancelTimer() {
    if (this.timer && !this.timer.isCanceled()) {
      this.timer.cancel();
    }
  }

  /**
   * Pauses the current timer
   */
  pauseTimer() {
    console.log('[AUTO SAVE] Pausing Timer');
    if (!this.timer) {
      this.timer = this.createTimer();
    }
    if (!this.timer.isPaused()) {
      this.timer.pause();
    }
  }

  /**
   * Resumes the current timer
   */
  resumeTimer() {
    console.log('[AUTO SAVE] Resuming Timer');
    if (this.timer && this.timer.isPaused()) {
      this.timer.resume();
    }
  }

  /**
   * Restarts the current timer
   */
  restartTimer() {
    if (this.timer && this.timer.isCanceled()) {
      this.timer.restart();
    }
  }

  first(): T {
    if (!this._items.length) {
      return null;
    }
    return this._items[0];
  }

  last(): T {
    if (!this._items.length) {
      return null;
    }
    return this._items[this._items.length - 1];
  }

  swap(items: Array<T>) {
    this._items = items;
    if (this.options.autoExecuteEnabled) {
      this.handleTimeout();
    }
  }

  private handleTimeout() {
    this.cancelTimer();
    this.timer = this.createTimer();
  }

  cleanQueue() {
    this._items = [];
  }

  get allowMultipleReads() {
    return this.options.allowMultipleReads;
  }

  setAllowMultipleReads(value: boolean) {
    this.options.allowMultipleReads = value;
  }

  lockQueue(value: boolean) {
    this.options.lockQueue = value;
  }

  private createTimer(): Timer {
    return new Timer(() => {
      this.execute();
    }, this.options.maxTimeout > 0 ? this.options.maxTimeout : 0);
  }
}
