import {
  AlertButton,
  AlertButtonRole,
  AlertDictionaryItem,
  DEFAULT_ALERT_BUTTONS
} from "../../../core/dictionary/alert.dictionary";
import {DEFAULT_LOADING_CONFIG, LoadingDictionaryItem} from "../../../core/dictionary/loading.dictionary";
import {ToastDictionaryItem} from "../../../core/dictionary/toast.dictionary";


export const LOGIN_DICTIONARY = {
  ALERTS: {

    ERROR_PERMISSIONS: (onButtonClick: (role: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.no_permissions_alert.title',
        subtitle: 'splash.alerts.no_permissions_alert.subtitle',
        message: 'splash.alerts.no_permissions_alert.message',
        buttons: [DEFAULT_ALERT_BUTTONS.EXIT],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_INVALID_STORE: (onButtonClick: (role: AlertButtonRole | string) => void) =>
      new AlertDictionaryItem({
        title: 'app_config.alerts.errorLogin.title',
        message: 'app_config.alerts.errorLogin.message',
        buttons: [
          DEFAULT_ALERT_BUTTONS.CANCEL,
          new AlertButton('buttons.reset_all', 'resetAll'),
          DEFAULT_ALERT_BUTTONS.RETRY,
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_QR_CONFIGURATION: (onButtonClick: (role: AlertButtonRole | string) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.error_qr_configuration.title',
        message: 'splash.alerts.error_qr_configuration.message',
        buttons: [
          new AlertButton('splash.alerts.error_qr_configuration.manual', 'manual'),
          DEFAULT_ALERT_BUTTONS.RETRY
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_INVALID_CONFIGS: (errorMessage: string, onButtonClick: (role: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.error_update_validation.title',
        message: errorMessage,
        buttons: [
          DEFAULT_ALERT_BUTTONS.CANCEL,
          DEFAULT_ALERT_BUTTONS.OK
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    WARNING_INVALID_CONFIGS: (warningMessage: string, onButtonClick: (role: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.warning_alert_configuration.title',
        message: warningMessage,
        buttons: [
          DEFAULT_ALERT_BUTTONS.RETRY,
          DEFAULT_ALERT_BUTTONS.CONTINUE
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_UNKNOWN: (onButtonClick: (role: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'setup.alerts.error.title',
        message: 'setup.alerts.error.message',
        buttons: [
          DEFAULT_ALERT_BUTTONS.CANCEL,
          DEFAULT_ALERT_BUTTONS.RETRY
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_NETWORK: (onButtonClick: (role: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.error_update.title',
        message: 'splash.alerts.error_update.message',
        buttons: [
          DEFAULT_ALERT_BUTTONS.CANCEL,
          DEFAULT_ALERT_BUTTONS.RETRY
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_INVALID_DEPARTMENT_ZONE: (message: string, onButtonClick: (role: AlertButtonRole | string) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.retry_zone_dept.title',
        message: message,
        buttons: [
          new AlertButton('splash.alerts.retry_zone_dept.logout', 'logout'),
          DEFAULT_ALERT_BUTTONS.RETRY
        ],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_SERVER_CONNECTING: (onButtonClick?: (btnRole: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'login.alerts.login_error.title',
        message: 'login.alerts.login_error.server',
        buttons: [DEFAULT_ALERT_BUTTONS.RETRY],
        config: {backdropDismiss: false, onButtonClick}
      }),
    ERROR_GETTING_CONFIGS: () =>
      new AlertDictionaryItem({
        title: 'login.alerts.login_error.title',
        message: 'Error getting Configurations, please check your network connection',
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false}
      }),
    RESET_ALL: (onButtonClick?: (btnRole: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'login.alerts.resetALL.title',
        message: 'login.alerts.resetALL.message',
        buttons: [DEFAULT_ALERT_BUTTONS.CANCEL, DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false, onButtonClick}
      }),
    WARNING_DANGER_ZONE: (onButtonClick?: (btnRole: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'app_config.alerts.reset.title',
        message: 'app_config.alerts.reset.message',
        buttons: [DEFAULT_ALERT_BUTTONS.CANCEL, DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false, onButtonClick}
      }),
    USER_IS_ROOT_PERMISSIONS_ERROR: () =>
      new AlertDictionaryItem({
        title: 'splash.alerts.no_permissions_alert.title',
        message: 'config.toasts.atLeastOneModuleRight',
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false}
      }),
    DEVICE_IS_ROOT_ERROR: (onButtonClick?: (btnRole: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'splash.alerts.error_alert_rooted_device.title',
        message: 'splash.alerts.error_alert_rooted_device.message',
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false, lockBackButton: true, onButtonClick}
      }),
    USER_PERMISSIONS_ERROR: (onButtonClick?: (btnRole: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'setup.alerts.error_permissions.title',
        message: 'setup.alerts.error_permissions.message',
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false, lockBackButton: true, onButtonClick}
      }),
    GENERIC_LOGIN_ERROR: () =>
      new AlertDictionaryItem({
        title: 'login.alerts.login_error.title',
        message: 'login.alerts.error.message',
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false}
      }),
    CUSTOM_SERVER_ERROR: (message: string) =>
      new AlertDictionaryItem({
        title: 'login.alerts.login_error.title',
        message: message,
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false}
      }),
    UNKNOWN_ERROR: () =>
      new AlertDictionaryItem({
        title: 'login.alerts.login_error.title',
        message: 'login.toasts.unknownError',
        buttons: [DEFAULT_ALERT_BUTTONS.OK],
        config: {backdropDismiss: false}
      }),
    INVALID_QR: (onButtonClick?: (btnRole: AlertButtonRole) => void) =>
      new AlertDictionaryItem({
        title: 'setup.alerts.invalid_qr.title',
        message: 'setup.alerts.invalid_qr.message',
        buttons: [new AlertButton('setup.alerts.invalid_qr.manual','manual')],
        config: {backdropDismiss: false, onButtonClick: onButtonClick}
      })
  },
  LOADINGS: {
    AUTHENTICATING: () => new LoadingDictionaryItem('login.loading.authenticating'),
    BOOT: () => new LoadingDictionaryItem(' ', {...DEFAULT_LOADING_CONFIG, backdropDismiss: false}),
    INITIALIZING: () => new LoadingDictionaryItem('encode_tags.alert.init.title', {...DEFAULT_LOADING_CONFIG, backdropDismiss: true})
  },
  TOASTS: {
    DANGER_ZONE: (clickNumber: number) => new ToastDictionaryItem(
      {label: 'app_config.toast.message', params: {clicks: clickNumber}},
      {duration: 1000, position: 'top', createOrUpdateExisting: true}
    ),
    RESET_SETTINGS: (clickNumber: number) => new ToastDictionaryItem(
      {label: 'login.toasts.resetSettings', params: {clicks: clickNumber}},
      {duration: 1000, position: 'top', createOrUpdateExisting: true}
    ),
    ADVANCED_SETTINGS_ENABLED: () => new ToastDictionaryItem(
      'app_config.toast.advancedEnabled',
      {duration: 1000, position: 'top', createOrUpdateExisting: true}
    ),
    EXPIRED_LOGIN: () => new ToastDictionaryItem(
      {label:'login.toasts.expired', params:{}},
      {duration: 1000, position: 'top', createOrUpdateExisting: true}
    )
  }
}
