import {NgModule} from "@angular/core";
import {StateService} from "./state.service";
import {StateManagerService} from "./state-manager.service";
import {AppState} from "./app/app.state";
import {NavState} from "./nav/nav.state";
import {ModalState} from "./modal/modal.state";
import {LoadingState} from "./loading/loading.state";
import {AlertState} from "./alert/alert.state";
import {PlatformState} from "./platform/platform.state";
import {ToastState} from "./toast/toast.state";
import {KeyboardState} from "./keyboard/keyboard.state";
import {CameraState} from "./camera/camera.state";
import {HistoryState} from "./history/history.state";
import {ThemeState} from "./theme/theme.state";
import {ProductState} from "./products/product.state";

@NgModule({
  providers: [
    StateService,
    StateManagerService,
    AppState,
    NavState,
    ModalState,
    LoadingState,
    AlertState,
    PlatformState,
    ToastState,
    KeyboardState,
    CameraState,
    HistoryState,
    ThemeState,
    ProductState
  ]
})
export class StateCoreModules {
}
