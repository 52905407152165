import {AlertButton, AlertController} from '@ionic/angular';
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {BackButtonService} from "../../../providers/back-button.service";
import {EventManagerService} from "source-ui-commons";
import {DEFAULT_ALERTS_CONFIG} from "../../dictionary/alert.dictionary";
import {BACK_BUTTON_PRIORITY} from "../../../providers/navigation/nav.constants";
import {Alert} from "./alert.actions";


@Injectable()
export class AlertState {

  private ALERT = {
    DEFAULTS: {
      backdropDismiss: false,
      dismissOnPageChange: true,
    }
  };

  private stack: {alert: HTMLIonAlertElement, unregister: () => void}[] = [];

  constructor(private alertCtrl: AlertController,
              private translate: TranslateService,
              private backButton: BackButtonService,
              private eventManager: EventManagerService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(Alert.ShowAlert.getName()).subscribe(this.showAlert);
    this.eventManager.subscribe(Alert.ShowAlertDict.getName()).pipe(
      map((action: Alert.ShowAlertDict) => { // Fill default values if missing
        if (!action.alert.params.config) {
          action.alert.params.config = DEFAULT_ALERTS_CONFIG
        }
        return action;
      }),
      map((action: Alert.ShowAlertDict) => {
        if (typeof action.alert.params.message !== 'string') {
          Object.keys(action.alert.params.message.params).forEach((key) => {
            if (typeof action.alert.params.message['params'][key] === 'string') {
              action.alert.params.message['params'][key] = this.translate.instant(action.alert.params.message['params'][key])
            }
          })
        }
        if (typeof action.alert.params.title !== 'string') {
          Object.keys(action.alert.params.title.params).forEach((key) => {
            if (typeof action.alert.params.title['params'][key] === 'string') {
              action.alert.params.title['params'][key] = this.translate.instant(action.alert.params.title['params'][key])
            }
          })
        }
        return new Alert.ShowAlert({
          header: typeof action.alert.params.title === 'string'
            ? this.translate.instant(action.alert.params.title)
            : this.translate.instant(action.alert.params.title.label, action.alert.params.title.params),
          message: typeof action.alert.params.message === 'string'
            ? this.translate.instant(action.alert.params.message)
            : this.translate.instant(action.alert.params.message.label, action.alert.params.message.params),
          buttons: action.alert.params.buttons?.map(btn => <AlertButton>{
            text: this.translate.instant(btn.label),
            role: btn.role,
            handler: () => action.alert.params.config.onButtonClick?.call(this, btn.role)
          }),
          inputs: action.alert.params.inputs || [],
      }, action.alert.params.config?.onDismiss, action.alert.params.config?.lockBackButton)})
    ).subscribe(this.showAlert);
    this.eventManager.subscribe(Alert.HideAlerts.getName()).subscribe(this.hideAlerts);
  }

  /**
   * Commands
   */

  showAlert = (action: Alert.ShowAlert) => {
    const options = {...this.ALERT.DEFAULTS, ...action.options};
    options.cssClass = options.cssClass || '';
    options.mode = 'ios';
    options.message = String(options.message).replace(/<br\s*[\/]?>/gi, '\n');
    this.alertCtrl.create(options).then((alert) => {
      const unregister = this.backButton.registerBackButtonAction(() => {
        if (!action.lockBackButton) {
          alert.dismiss();
        } else {
          console.warn('Back button locked by Alert');
        }
      }, BACK_BUTTON_PRIORITY.ALERT);
      alert.onDidDismiss().then((data) => {
        this.removeFromStack(alert);
        if (action.hasOwnProperty("onDismiss") && action.onDismiss != undefined) {
          action.onDismiss(data);
        }
      })
      alert.present().then(() => {
        this.addToStack(alert, unregister);
      })
    })
  };

  hideAlerts = (action: Alert.HideAlerts) => {
    this.stack.forEach(a => a.unregister());
    this.stack.forEach(a => a.alert.dismiss());
    this.clearAllFromStack();
  }

  private addToStack(alert: HTMLIonAlertElement, unregister: () => void) {
    this.stack.push({alert, unregister});
  }

  private removeFromStack(alert: HTMLIonAlertElement) {
    this.stack.find(a => a.alert === alert)?.unregister();
    this.stack = this.stack.filter(a => a.alert !== alert);
  }

  private clearAllFromStack() {
    this.stack = [];
  }
}
