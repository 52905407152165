import {YtemBaseService} from '../base/YtemBaseService';
import {Observable} from 'rxjs';
import {TagExtended} from '../model/status.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {YTEM_PLATFORM} from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class StatusApiService extends YtemBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public statuses(epcs: Array<string>): Observable<Array<TagExtended>> {
    const path = this.getPath(YTEM_PLATFORM.STATUS_API.BASE);
    const body = {
      query: epcs
    };
    return this.http.post<Array<TagExtended>>(path, body, {headers: this.getHeaders()});
  }
}
