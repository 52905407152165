import {environment} from '../environments/environment'
import {AES, enc} from 'crypto-js'

const SECRET_KEY = atob(environment.key);
const developMode = !environment.production;
const secureStorage = {
  encrypt: function encrypt(data) {
    data = AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    let dataEncripted = AES.decrypt(data, SECRET_KEY);
    try {
      dataEncripted = dataEncripted.toString(enc.Utf8);
      return dataEncripted;
    } catch (e) {
      console.log('ERROR DECRYPT DATA', e);
      return data;
    }
  }
};

export function encode_utf8(s) {
  return unescape(encodeURIComponent(s));
}

export function decode_utf8(s) {
  return decodeURIComponent(escape(s));
}

export function encryptData(data: string) {
  const dataParse = btoa(encode_utf8(data));
  return secureStorage.encrypt(dataParse);
}

export function decryptData(data: string) {
  try {
    const dataCoded = secureStorage.decrypt(data);
    return decode_utf8(atob(dataCoded));
  } catch (e) {
    return data;
  }
}

/**
 * Function to save data in local storage
 * */
export function saveDataEncryptedLocalStorage(key: string, value: string, isDevMode = developMode) {
  if(isDevMode) {
    localStorage.setItem(key, value);
  }else {
    localStorage.setItem(key, encryptData(value));
  }
}

/**
 * Function to get data from local storage
 * */
export function getDataEncryptedLocalStorage(key: string, isDevMode = developMode) {
  const storageData = localStorage.getItem(key);
  if(!isDevMode && !!storageData){
    return decryptData(storageData);
  } else {
    return storageData;
  }
}

/**
 * Function to return data encrypted from an input
 * */
export function applyEncryption(value: string) {
  if(developMode) {
    return value;
  }else {
    return encryptData(value);
  }
}

/**
 * Function to return data decrypted from an input
 * */
export function applyDecryption(value: string) {
  if(!developMode && !!value) {
    return decryptData(value);
  }else {
    return value;
  }
}

export function removeDataLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function cleanStorage() {
  localStorage.clear();
}
