import {Injectable} from "@angular/core";
import {StateService} from "../state.service";
import {NavService} from "../../../providers/navigation/nav.service";
import {EventManagerService} from "source-ui-commons";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {from, mapTo, Observable, switchMap, throwError} from "rxjs";
import {delay, tap} from "rxjs/operators";
import {Nav} from "./nav.actions";
import {Modal} from "../modal/modal.actions";
import {Alert} from "../alert/alert.actions";

@Injectable()
export class NavState {
  constructor(private state: StateService,
              private eventManager: EventManagerService,
              private navService: NavService) {
    this.subscribeToEvents();
    this.navService.$currentComponent.subscribe(component => this.setStateForActivePage(component))
  }

  public subscribeToEvents() {
    this.eventManager.subscribeRx(Nav.NavPushRx.getName(), this.navPushRx);
    this.eventManager.subscribeRx(Nav.NavPushRootRx.getName(), this.navPushRootRx);
    this.eventManager.subscribeRx(Nav.NavPopRx.getName(), this.navPopRx);
    this.eventManager.subscribeRx(Nav.UnsafeNavPopRx.getName(), this.unsafeNavPopRx);
    this.eventManager.subscribeRx(Nav.NavSafePushModule.getName(), this.navSafePushModule);
    this.eventManager.subscribeRx(Nav.NavReplaceRx.getName(), this.navReplaceRx);
  }

  private setStateForActivePage(component: any) {
    const newState = {...this.state.nav};
    newState.active = component.name;

    const nav = {...newState};
    this.state.patchState(nav, ['nav.sourceModule', 'nav.active']);
  }

  navPushRootRx = (action: Nav.NavPushRootRx): Observable<boolean> => {
    if (typeof action.page === 'string') { // specific routes
      return fromPromise(this.navService.pushRootPage(action.page, action.params, action.options)).pipe(mapTo(true));
    } else if (action.page.route) {
      return fromPromise(this.navService.pushRootPage(action.page.route, action.params, action.options)).pipe(mapTo(true));
    } else {
      return throwError('NavPushRootRx requires a page with route property or a route string!');
    }
  }

  navPushRx = (action: Nav.NavPushRx): Observable<boolean> => {
    if (typeof action.page === 'string') { // specific routes
      return fromPromise(this.navService.pushPage(action.page, action.params, action.options)).pipe(mapTo(true));
    } else if (action.page.route) {
      return fromPromise(this.navService.pushPage(action.page.route, action.params, action.options)).pipe(mapTo(true));
    } else {
      return throwError('NavPushRx requires a page with route property or a route string!');
    }
  };

  navSafePushModule = (action: Nav.NavSafePushModule): Observable<void> => {
    let pushAction: Observable<void>;
    if (typeof action.page === 'string') { // specific routes
      pushAction = fromPromise(this.navService.safePushPage(action.page, action.params));
    } else if (action.page.route) {
      pushAction = fromPromise(this.navService.safePushPage(action.page.route, action.params));
    } else {
      return throwError('NavPushRx requires a page with route property or a route string!');
    }
    return pushAction.pipe(
      tap(() => {
        this.eventManager.publish(new Modal.HideModals());
        this.eventManager.publish(new Alert.HideAlerts());
      })
    );
  }

  navReplaceRx = (action: Nav.NavReplaceRx): Observable<void> => {
    if (typeof action.page === 'string') { // specific routes
      return from(this.navService.popUnsafeCurrentPage(action.outPageOptions)).pipe(
        delay(100),
        switchMap(() => this.navService.pushPage(<string>action.page, action.params, action.inPageOptions))
      );
    } else if (action.page.route) {
      return from(this.navService.popUnsafeCurrentPage(action.outPageOptions)).pipe(
        delay(100),
        switchMap(() => this.navService.pushPage((<any>action.page).route, action.params, action.inPageOptions))
      );
    } else {
      return throwError('NavReplaceRx requires a page with route property or a route string!');
    }
  };

  navPopRx = (action: Nav.NavPopRx): Observable<boolean> => {
    return fromPromise(this.navService.popCurrentPage(action.options, action.route)).pipe(
      mapTo(true));
  };

  unsafeNavPopRx = (action: Nav.UnsafeNavPopRx): Observable<boolean> => {
    return fromPromise(this.navService.popUnsafeCurrentPage(action.options)).pipe(
      mapTo(true));
  };
}
