import {EventModel, RxEvent} from "source-ui-commons";
import {SupportedFormat} from "@capacitor-community/barcode-scanner/dist/esm/definitions";

export namespace Camera {
  export type CameraScanResponse = {
    tag: string,
    message: 'valid' | 'invalidHexa' | 'invalid' | 'duplicated'
  }

  export type CameraScanParams = {
    singleShot: boolean,
    continueReadingOnDuplicated: boolean,
    valuesToIgnore?: string[],
    regex?: string,
    readtypes?: SupportedFormat[]
  };

  export class qrDuplicatedModel {
    tag: string;
    message: string;
  }

  /**
   * Commands
   */

  export class EnableFlash extends EventModel {
    override type = '[CAMERA] enable flash';

    constructor() {
      super();
    }

  }

  export class DisableFlash extends EventModel {
    override type = '[CAMERA] disable flash';

    constructor() {
      super();
    }

  }

  export class GetFlashStatus extends EventModel {
    override type = '[CAMERA] Get flash status';
  }

  export class HideCamera extends RxEvent<void> {
    override type = '[CAMERA] hide camera';

    constructor(todelete1?: boolean, todelete2?: boolean) {
      super();
    }
  }

  export class HideCameraByBackButton extends EventModel {
    override type = '[CAMERA] hide camera by back button';
  }

  export class ShowScanCamera extends RxEvent<void> {
    override type = '[CAMERA] show camera';

    constructor(public scanParams: CameraScanParams) {
      super();
    }
  }

  export class SubscribeCameraScan extends RxEvent<CameraScanResponse> {
    override type = '[CAMERA] subscribe camera scan';
    override keepAlive= true
  }

  export class AddTagsToIgnore extends EventModel {
    override type = '[CAMERA] show camera';

    constructor(public tags: Array<string>) {
      super();
    }
  }

  export class RemoveTagsFromCameraQueue extends EventModel {
    override type = '[CAMERA] remove tags';
    constructor(public tags: string[]) {
      super();
    }
  }

  export class SubscribeBarcodeRead extends RxEvent<string> {
    override type = '[CAMERA] Subscribe barcode read';
    override keepAlive = true;
  }

  export class SubscribeDuplicateBarcodeRead extends RxEvent<qrDuplicatedModel> {
    override type = '[CAMERA] Subscribe Duplicated barcode read';
    override keepAlive = true;
  }

  export class SubscribeHexaBarcodeRead extends RxEvent<qrDuplicatedModel> {
    override type = '[CAMERA] Subscribe Hexa barcode read';
    override keepAlive = true;
  }

  export class SubscribeScannedTagError extends RxEvent<string> {
    override type = '[CAMERA] Subscribe scanned tag error';
    override keepAlive = true;
  }

  export class SubscribeCameraError extends RxEvent<string> {
    override type = '[CAMERA] Subscribe camera error';
    override keepAlive = true;
  }

  export class SubscribeCameraBackAction extends RxEvent<string> {
    override type = '[CAMERA] Subscribe Camera Back Action';
    override keepAlive = true;
  }

  export class SubscribeBackAction extends EventModel {
    override type = '[CAMERA] Subscribe Back Action';
    keepAlive = true;
  }

  export class LockReadings extends EventModel {
    override type = '[CAMERA] Lock reads';
  }

  export class UnlockReadings extends EventModel {
    override type = '[CAMERA] unlock reads';
  }

  export class PurgeTags extends EventModel {
    override type = '[CAMERA] Clean tags';
  }

  export class SetTags extends EventModel {
    override type = '[CAMERA] set tags';

    constructor(public tags: Array<string>) {
      super();
    }
  }

  export class ReviewCamera extends RxEvent<boolean> {
    override type = '[CAMERA] review camera';

    constructor() {
      super();
    }
  }

}
