import {YtemBaseService} from '../base/YtemBaseService';
import {map, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {YTEM_PLATFORM} from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends YtemBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public login(username: string, password: string): Observable<string> {
    const path = this.getPath(YTEM_PLATFORM.AUTH.BASE);
    const body = {username, password};
    return this.http.post<any>(path, body, {headers: this.getHeaders()})
      .pipe(
        map((response) => {
          if (response.token) {
            return response.token;
          } else {
            throw new Error('No token found');
          }
        })
      );
  }

  public logout(): Observable<any> {
    const path = this.getPath(YTEM_PLATFORM.AUTH.LOGOUT);
    return this.http.post(path, {token: this.getToken()},
      {
        headers: this.getHeaders()
      });
  }
}
