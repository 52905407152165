import {EpcisEvent} from '../../../app/providers/ytem/model/epcis.model';
import {DetailModalModel} from '../../../app/components/details-modal/detail-modal.model';
import {EventModel} from "source-ui-commons";
import {ItemModel, Product} from "../../../app/providers/ytem/model/product.model";

export namespace History {
  export class AddToHistory extends EventModel {
    override type = '[History] AddToHistory';
    constructor(public search: string, public obj: DetailModalModel, public event: EpcisEvent[], public product: Product, public item: ItemModel) {
      super()
    }
  }

  export class CleanHistory extends EventModel {
    override type = '[History] CleanHistory';
    constructor() {
      super()
    }
  }

}
