import {YtemBaseService} from '../base/YtemBaseService';
import {YTEM_PLATFORM} from '../../../app.constants';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CxLocation, Fixture} from '../model/location.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class LocationApiService extends YtemBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public singleLocationInfo(code: string): Observable<CxLocation | null> {
    const path = this.getPath(YTEM_PLATFORM.LOCATION_API.BASE);
    return this.http.get<Array<CxLocation>>(path, {headers: this.getHeaders()})
      .pipe(
        map((locations) => {
          const currentLocation = locations.find(loc => loc.code === code);
          if (currentLocation) {
            return currentLocation;
          }
          return null;
        }),
      );
  }


  public locationTreeFriendlyNames(level?: string, loadFixturesValues?: boolean): Observable<Map<string, CxLocation>> {
    let path = this.getPath(YTEM_PLATFORM.LOCATION_API.BASE);
    if (level) {
      path = path += '?level=' + level;
    }

    const mapBizLocObject: Map<string, CxLocation> = new Map<string, CxLocation>();
    const mapFriendlyNames: Map<string, string> = new Map<string, string>();
    return this.http.get<Array<CxLocation>>(path, {headers: this.getHeaders()})
      .pipe(
        tap(locationTree => this.fillLocationsMap(locationTree, mapBizLocObject)),
        /*switchMap(locationTree => {
          const premises = locationTree.filter(cxLocation => cxLocation.level === 'premise');
          // @ts-ignore
          const $arrFixtures = [];
          if (loadFixturesValues) {
            // @ts-ignore
            premises.map(premise => $arrFixtures.push(this.loadFixtures(premise.code).pipe(
              tap(fixtures => this.fillFixturesMap(fixtures, mapFriendlyNames)),
              catchError(() => of([])),
            )));
          }
          // @ts-ignore
          return zip(...$arrFixtures);
        }),*/
        map(res => mapBizLocObject),
      );
  }

  public loadFixtures(premiseCode: string): Observable<Array<Fixture>> {
    let path = this.getPath(YTEM_PLATFORM.LOCATION_API.BASE);
    path += `/code/premise/${premiseCode}/fixtures`;
    return this.http.get<Array<Fixture>>(path, {headers: this.getHeaders()});
  }

  private fillLocationsMap(locations: Array<CxLocation>, map: Map<string, CxLocation>) {
    if (locations && locations.length) {
      locations.map(location => {
        if (location.bizLocation != null) {
          map.set(location.bizLocation, location);
        }
      });
    }
  }

  private fillFixturesMap(fixtures: Array<Fixture>, map: Map<string, string>) {
    if (fixtures && fixtures.length) {
      fixtures.map(fixture => {
        if (fixture.name != null) {
          map.set(fixture.bizLocation, fixture.name);
        }
      });
    }
  }
}
