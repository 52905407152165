import {Injectable} from "@angular/core";
import {StateService} from "../state.service";
import {EventManagerService} from "source-ui-commons";
import {KEYBOARD} from "../../../app/app.constants";
import {PlatformNamespace} from "../platform/platform.actions";
import {KeyboardNameSpace} from "./keyboard.actions";
import {Keyboard} from "@capacitor/keyboard";

@Injectable()
export class KeyboardState {
  constructor(private state: StateService,
              private eventManager: EventManagerService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(PlatformNamespace.PlatformReady.getName()).subscribe(this.platformReady);
    this.eventManager.subscribe(KeyboardNameSpace.KeyboardDidShow.getName()).subscribe(this.keyboardDidShow);
    this.eventManager.subscribe(KeyboardNameSpace.KeyboardDidHide.getName()).subscribe(this.keyboardDidHide);
    this.eventManager.subscribe(KeyboardNameSpace.HideKeyboard.getName()).subscribe(this.hideKeyboard);
  }

  platformReady = (action: PlatformNamespace.PlatformReady) => {
    if (this.state.platform.deviceInfo.capacitor && this.state.platform.deviceInfo.platform === 'ios') {
      Keyboard.setAccessoryBarVisible({
        isVisible: true
      }).then();
    }
    window.addEventListener(KEYBOARD.KEYBOARD_DID_SHOW_EVENT, () => this.eventManager.publish( new KeyboardNameSpace.KeyboardDidShow()));
    window.addEventListener(KEYBOARD.KEYBOARD_DID_HIDE_EVENT, () => this.eventManager.publish( new KeyboardNameSpace.KeyboardDidHide()));
  };

  keyboardDidShow = (action: KeyboardNameSpace.KeyboardDidShow) => {
    const keyboard = {...this.state.keyboard};
    keyboard.isOpen = true;
    this.state.patchState(keyboard, ['keyboard.isOpen'])
  };

  keyboardDidHide = (action: KeyboardNameSpace.KeyboardDidHide) => {
    const keyboard = {...this.state.keyboard};
    keyboard.isOpen = false;
    this.state.patchState(keyboard, ['keyboard.isOpen'])
  };

  hideKeyboard = (action: KeyboardNameSpace.HideKeyboard) => {
    if (this.state.platform.deviceInfo.capacitor) {
      Keyboard.hide().then();
    }
  };
}
