import {Component} from '@angular/core';
import {NavController} from "@ionic/angular";

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {
  currentRoute = 'home';

  constructor(private navController: NavController) {
  }

  navigate(route: string) {
    this.navController.navigateRoot('/tabs/' + route).then(() => {
      this.currentRoute = route;
    })
  }

}
