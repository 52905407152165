import {Injectable} from '@angular/core';
import {Observable, Observer, of, Subject} from 'rxjs';
import {EventManagerService} from "source-ui-commons";
import {StorageService} from "../../services/storage/storage.service";
import {SingletonStateSelectorsService} from "./singleton-state-selectors.service";
import {cleanStorage} from "../../utils/encryption.utils";

@Injectable()
export class StateService extends SingletonStateSelectorsService {

  constructor(public override eventManager: EventManagerService,
              public override storage: StorageService) {
    super(storage, eventManager);
  }

  /**
   * Observe changes
   * @param path
   */
  public observeChanges(path: string): Observable<any> {
    if (path) {
      let subject = new Subject();

      if (this.subjectMap.has(path)) {
        subject = this.subjectMap.get(path);
      } else {
        this.subjectMap.set(path, subject);
      }
      return new Observable((observer: Observer<any>) => {
        subject.subscribe((data: any) => {
          try {
            observer.next(data);
          } catch (e) {
            console.log(e);
          }
        });
        this.returnActualVariable(path, subject);
      });
    } else {
      return of()
    }
  }

  /**
   * Return actual value of the object
   * @param path
   * @param observer
   */
  private returnActualVariable(path: string, observer: Subject<any>) {
    const pathArr = path.split('.');
    if (pathArr.length) {
      let currentState = null;
      switch (pathArr[0]) {
        case 'nav':
          currentState = this.nav;
          break;
        case 'keyboard':
          currentState = this.keyboard;
          break;
        case 'platform':
          currentState = this.platform;
          break;
        case 'camera':
          currentState = this.camera;
          break;
        case 'history':
          currentState = this.history;
          break;
        case 'theme':
          currentState = this.theme;
          break;
        case 'product':
          currentState = this.product;
          break;
        default:
          console.error('Observe changes not found:', path);
          break;
      }

      if (currentState) {
        pathArr.shift();//Remove first element

        const value = this.getChangedValueRecursive(currentState, pathArr);
        if (!(value instanceof EmptyReference)) {
          observer.next(value);
        }
      }
    } else {
      console.error('Observe changes error. Not a valid path:', path);
    }
  }


  /**
   * Patch state of a child
   * @param newState
   * @param changesPath
   */
  public patchState(newState: any, changesPath: Array<string>) {
    if (!newState.hasOwnProperty('kind')) {
      return;
    }
    switch (newState['kind']) {
      case 'NavStateModel':
        this.nav = newState;
        break;
      case 'KeyboardStateModel':
        this.keyboard = newState;
        break;
      case 'PlatformStateModel':
        this.platform = newState;
        break;
      case 'CameraStateModel':
        this.camera = newState;
        break;
      case 'HistoryStateModel':
        this.history = newState;
        break;
      case 'ThemeStateModel':
        this.theme = newState;
        break;
      case 'ProductStateModel':
        this.product = newState;
        break;
      default:
        break;
    }
    this.updateAppState();
    this.notifyChanges(newState, changesPath);
    if (newState.hasOwnProperty('db') && newState.db) {
      this.saveInLocalStorage(newState, changesPath);
    }
  }

  /**
   * Notify changes
   * @param newState
   * @param changesPath
   */
  private notifyChanges(newState: any, changesPath: Array<string>) {
    for (const path of changesPath) {
      if (this.subjectMap.has(path)) {
        const pathArr = path.split('.');
        pathArr.shift();//Remove first element

        const value = this.getChangedValueRecursive(newState, pathArr);
        if (!(value instanceof EmptyReference)) {
          this.subjectMap.get(path).next(value);
        }
      }
    }
  }

  clear(): void {
    cleanStorage();
  }
}

export class EmptyReference {
  path: string;

  constructor(path: string) {
    this.path = path;
  }
}
