import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {YtemAuthenticationModel} from "../../../providers/ytem/model/ytemSettings";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {SetupModelMediator} from "./mediators/setup-model.mediator";
import {StateService} from "../../../../core/states/state.service";
import {StorageService} from "../../../../services/storage/storage.service";

@Injectable()
export class LoginService {

  private setupModelMediator = new SetupModelMediator(this.storage);
  constructor(private state: StateService,
              private storage: StorageService) {
  }

  public saveSetupModel(setupModel: YtemAuthenticationModel) {
    return fromPromise(this.setupModelMediator.saveSetupModel(setupModel));
  };

  getConfigurationFromStorage(): Observable<YtemAuthenticationModel> {
    return fromPromise(this.setupModelMediator.getSetupModel());
  }
}
