/**
 * This class is an extension to a timeout, improving and handling time functions like pause, resume or restart
 */
export class Timer {

  private timer: any;
  private startDate: number;
  private readonly delay: number;
  private remaining: number;
  private paused: boolean = false;
  private canceled: boolean = false;
  private readonly callback: () => void;

  /**
   * Receive the same params as a common timeout
   * @param {() => void} callback
   * @param {number} delay
   */
  constructor(callback: () => void, delay: number) {
    this.callback = callback;
    this.startDate = Date.now();
    this.delay = delay;
    this.remaining = delay;
    this.start();
  }

  /**
   * Starts the current timer, only called when instantiated
   */
  private start() {
    if (this.remaining) {
      this.startDate = Date.now();
      clearTimeout(this.timer);
      this.timer = setTimeout(this.callback, this.remaining);
      this.paused = false;
    }
  }

  /**
   * Pauses the current timer, calculates the remaining time
   */
  pause() {
    clearTimeout(this.timer);
    this.remaining -= Date.now() - this.startDate;
    this.paused = true;

    //Modified to be able to read barcodes
    this.canceled = false;
  }

  /**
   * Resumes the current timer, resets startDate
   */
  resume() {
    if (this.remaining && !this.canceled && this.paused) {
      this.startDate = Date.now();
      clearTimeout(this.timer);
      this.timer = setTimeout(this.callback, this.remaining);
      this.paused = false;

      //Modified to be able to read barcodes
      this.canceled = true;
    }
  }

  /**
   * Cancels the current timer, it prevents the user to be able to resume it again (must use restart)
   */
  cancel() {
    clearTimeout(this.timer);
    this.remaining = 0;
    this.canceled = true;
  }

  /**
   * Restarts a timer, it only works if "cancel" function was called first
   */
  restart() {
    if (!this.remaining && !this.paused && this.canceled) {
      this.startDate = Date.now();
      this.remaining = this.delay;
      clearTimeout(this.timer);
      this.canceled = false;
      this.timer = setTimeout(this.callback, this.remaining);
    }
  }

  isPaused() {
    return this.paused;
  }

  isCanceled() {
    return this.canceled;
  }

  getRemaining() {
    return this.remaining - (Date.now() - this.startDate);
  }
}
