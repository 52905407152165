import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {BackButtonService} from "../../../providers/back-button.service";
import {EventManagerService} from "source-ui-commons";
import {BACK_BUTTON_PRIORITY} from "../../../providers/navigation/nav.constants";
import {Loading} from "./loading.actions";
import {LoadingOptions} from "@ionic/core/dist/types/components/loading/loading-interface";

@Injectable()
export class LoadingState {
  public loading: HTMLIonLoadingElement;

  public isLoadingCreating = false;
  public isLoadingStopped = false;

  private DEFAULTS = {
    showBackdrop: true,
    enableBackdropDismiss: false,
    dismissOnPageChange: false,
    duration: 0,
    animated: true
  };

  private unregisterBackFunc: () => void;

  constructor(private loadingCtrl: LoadingController,
              private translate: TranslateService,
              private backButton: BackButtonService,
              private eventManager: EventManagerService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(Loading.ShowLoading.getName()).subscribe(this.showLoading);
    this.eventManager.subscribe(Loading.ShowLoadingDict.getName()).pipe(
      map((action: Loading.ShowLoadingDict) => new Loading.ShowLoading({
        message: typeof action.loading.label === 'string'
          ? this.translate.instant(action.loading.label)
          : this.translate.instant(action.loading.label.label, action.loading.label.params),
        backdropDismiss: action.loading.config.backdropDismiss,
        duration: action.loading.config.duration,
        cssClass: action.loading.config.cssClass,
        spinner: 'bubbles'
      }, action.loading.config?.lockBackButton !== undefined ? action.loading.config.lockBackButton : undefined))
    ).subscribe(this.showLoading);
    this.eventManager.subscribe(Loading.HideLoading.getName()).subscribe(this.hideLoading);
  }

  /**
   * Commands
   */
  showLoading = (action: Loading.ShowLoading) => {
    this.isLoadingStopped = false;
    if (this.loading) {
      this.loading.message = action.options.message || this.translate.instant('loading.content');
      this.loading.duration = action.options.duration || this.DEFAULTS.duration;
    } else if (this.isLoadingCreating) {
      return; // Do nothing, another loading is already under creation
    } else {
      this.isLoadingCreating = true;
      this.loadingCtrl.create({
        ...this.DEFAULTS,
        message: (action.options && action.options.message) ? action.options.message : this.translate.instant('loading.content'),
        ...action.options,
        backdropDismiss: action.options.backdropDismiss !== undefined ? action.options.backdropDismiss : false
      }).then((loading) => {
        this.loading = loading;
        this.isLoadingCreating = false;
        if (action.blockBackButton) {
          this.lockBackButton();
        }
        this.loading.onWillDismiss().then(() => {
          this.unlockBackButton();
          this.loading = undefined;
        });
        if (!this.isLoadingCreating && !this.isLoadingStopped && this.loading) { // Prevent too fast Show/Hide loadings
          this.loading.present().then(() => {
            try {
              const elemDiv = document.getElementsByTagName('ion-loading')[0];
              if (elemDiv) {
                document.body.appendChild(elemDiv);
              }
            } catch (e) {
              console.log('error append loading to body', e)
            }
          });
        } else {
          this.loading = undefined;
          this.unlockBackButton();
        }
      })
    }
  };

  lockBackButton() {
    this.unlockBackButton();
    this.unregisterBackFunc = this.backButton.registerBackButtonAction(() => {
    }, BACK_BUTTON_PRIORITY.MAX);
  }

  unlockBackButton() {
    if (this.unregisterBackFunc) {
      this.unregisterBackFunc();
      this.unregisterBackFunc = undefined;
    }
  }

  hideLoading = async (action: Loading.HideLoading) => {
    this.isLoadingStopped = true;
    if (this.loading) {
      await this.loading.dismiss();
    }
    this.unlockBackButton();
  };
}
