import {AlertOptions} from '@ionic/angular';
import {AlertDictionaryItem} from "../../dictionary/alert.dictionary";
import {EventModel} from "source-ui-commons";

export namespace Alert {
  export class ShowAlert extends EventModel {
    override type = '[Alert] Show an alert';

    constructor(public options: AlertOptions,
                public onDismiss: (data) => void = undefined,
                public lockBackButton: boolean = false) {
      super();
    }
  }

  export class ShowAlertDict extends EventModel {
    override type = '[Alert] Show an alert using dictionary';

    constructor(public alert: AlertDictionaryItem) {
      super();
    }
  }

  export class HideAlerts extends EventModel {
    override type = '[Alert] Hide alerts';
  }

}
