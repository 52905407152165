import {StateService} from "../../../../../core/states/state.service";
import {BehaviorSubject} from "rxjs";

export class UiViewModelMediator {
  private DEFAULT_IS_REMEMBER_ME_ENABLED_VALUE = true;

  public $host: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public $username: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public $password: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public $isRememberMeEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.DEFAULT_IS_REMEMBER_ME_ENABLED_VALUE);

  public $setupButtonDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public DEFAULT_PASSWORD = '***********';

  constructor() {
  }


  onHostChanged(host: string) {
    this.$host.next(host);
    this.validateFormFields();
  }

  onUsernameChanged(username: string) {
    this.$username.next(username);
    if (this.$password.value === this.DEFAULT_PASSWORD) {
      this.onPasswordChanged('');
    }
    this.validateFormFields();
  }

  onPasswordChanged(password: string) {
    this.$password.next(password);
    this.validateFormFields();
  }

  onRememberMeChanged(rememberMe: boolean) {
    this.$isRememberMeEnabled.next(rememberMe);
    this.validateFormFields();
  }

  validateFormFields() {
    const validForSetup = this.$host.value && this.$username.value && this.$password.value;
    this.$setupButtonDisabled.next(!validForSetup);
  }
}
