import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

export interface SettingsSupplyChainModel {
  feature: string;
  key: string;
  value: string;
}

export class ThemeClient {
  constructor(public http: HttpClient) {
  }

  getThemeVariables(filters: string[], path: string, headers: HttpHeaders): Observable<SettingsSupplyChainModel[]> {
    return this.http.post<SettingsSupplyChainModel[]>(
      path,
      {
        "order": {
          "property": "key",
          "direction": "ASC"
        },
        "filters": [
          {
            "property": "key",
            "operator": "EQ",
            "values": filters
          }
        ]
      },
      {headers: headers}
    )
  }
}
