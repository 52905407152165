import {ToastOptions} from '@ionic/angular';
import {TOAST_PRIORITY_DEFAULT, ToastDictionaryItem} from "../../dictionary/toast.dictionary";
import {EventModel} from "source-ui-commons";

export namespace Toast {
  export class ShowToast extends EventModel {
    override type = '[Toast] Add toast';

    constructor(public options: ToastOptions, public createOrUpdateExisting ?: boolean, public priority : number = TOAST_PRIORITY_DEFAULT) {
      super();
    }
  }

  export class ShowToastDict extends EventModel {
    override type = '[Toast] Add toast using dictionary';

    constructor(public toast: ToastDictionaryItem) {
      super();
    }
  }

}
