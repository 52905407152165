import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {EventManagerService} from "source-ui-commons";
import {ProductsService} from "../../../services/products/products.service";
import {ProductNamespace} from "./product.actions";

@Injectable()
export class ProductState {

  constructor(private eventManager: EventManagerService,
              private productsService: ProductsService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribeRx(ProductNamespace.GetProductImage.getName(), this.getProductImage);
  }

  getProductImage = (action: ProductNamespace.GetProductImage): Observable<any> => {
    return this.productsService.getProductImage(action.product, action.verifyCache, action.viewport);
  }
}
