import {STORAGE_SET_UP_MODEL} from "../../../../app.constants";
import {StorageService} from "../../../../../services/storage/storage.service";
import {YtemAuthenticationModel} from "../../../../providers/ytem/model/ytemSettings";

export class SetupModelMediator {
  constructor(private storage: StorageService) {
  }

  async getSetupModel(): Promise<YtemAuthenticationModel> {
    return await this.storage.get(STORAGE_SET_UP_MODEL);
  }
  async saveSetupModel(setupModel: YtemAuthenticationModel) {
    await this.storage.set(STORAGE_SET_UP_MODEL, setupModel);
  }
}
