import {EventModel} from "source-ui-commons";

export namespace KeyboardNameSpace {
  export class KeyboardDidHide extends EventModel{
    override type = '[Keyboard] Did hide';
  }

  export class KeyboardDidShow extends EventModel{
    override type = '[Keyboard] Did show';
  }

  export class HideKeyboard extends EventModel {
    override type = '[Platform] HideKeyboard'
  }

}
