import {YtemBaseService} from '../base/YtemBaseService';
import {Observable} from 'rxjs';
import {EpcisEvent, EpcisEventResponse, SearchRequestI} from '../model/epcis.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {YTEM_PLATFORM} from '../../../app.constants';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EpcisApiService extends YtemBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public search(request: SearchRequestI): Observable<EpcisEventResponse> {
    const path = this.getPath(YTEM_PLATFORM.EPCIS_API.BASE);
    return this.http.post<EpcisEventResponse>(path, request, {headers: this.getHeaders()});
  }

  public sendEvent(event: EpcisEvent): Observable<boolean> {
    const path = this.getPath(YTEM_PLATFORM.EPCIS_API.AMPQ);
    const body = {
      events: [event],
    };
    console.log('path', path);
    console.log('event', JSON.stringify(body));
    return this.http.post(path, body, {headers: this.getHeaders()})
      .pipe(map(() => true));
  }
}
