import {RxEvent} from "source-ui-commons";
import {Product} from "../../../app/providers/ytem/model/product.model";
import {ProductImageViewport} from "../../../app/pipes/product-image/product-image";

export namespace ProductNamespace {
  export class GetProductImage extends RxEvent<any>{
    override type = '[Product] Get Products';

    constructor(public product: Product, public verifyCache?: boolean, public viewport?: ProductImageViewport) {
      super();
    }
  }
}
