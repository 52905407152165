<ng-template #loginForm>
  <div style="display: flex; flex-direction: column; gap: 10px">
    <app-input #inputHost
               [text]="(viewModel.$host | withNgZone | async)"
               [placeholder]="'setup.placeholders.host' | translate"
               (textChanged)="viewModel.onHostChanged($event)"
               [maxLength]="60"
               [fixedInputText]="'https://'"
               [validationRegex]="validationInputHost">
    </app-input>
    <app-input #inputUser
               [text]="viewModel.$username | withNgZone | async"
               [placeholder]="'setup.placeholders.username' | translate"
               (textChanged)="viewModel.onUsernameChanged($event)"
               [maxLength]="60"
               [loginCase]="true"
               [validationRegex]="validationInputUser">
    </app-input>
    <app-input #inputPassword
               [text]="viewModel.$password | withNgZone | async"
               [placeholder]="'login.placeholders.password' | translate"
               inputType="password"
               [maxLength]="128"
               (textChanged)="viewModel.onPasswordChanged($event)"
               [showClearButton]="false"
               [showPasswordButton]="true">
    </app-input>
    <generic-switch style="margin-left: 10px;" [text]="'login.labels.remember_me' | translate" [id]="'rememberMe'"
                    [checked]="viewModel.$isRememberMeEnabled | withNgZone | async"
                    (checkedChange)="viewModel.onRememberMeChanged($event)"/>
    <div style="display: flex; justify-content: stretch; gap: 10px;">
      <generic-button style="width: 0; flex-grow: 1; margin: 12px 0;"
                      [text]="('buttons.validate' | translate)"
                      [disabled]="viewModel.$setupButtonDisabled | withNgZone | async"
                      (clickEvent)="login()"/>
    </div>
    <app-info hide-with-keyboard
              [uuid]="uuid"
              [brand]="'global.brand' | translate"
              [version]="'v' + version"
    />
  </div>
</ng-template>


<app-scaffold [animationUtils]="animationUtils" [transparentBackground]="true">
  <div header class="header-logo"></div>
  <div content>
    <app-brand-backgroud-component></app-brand-backgroud-component>
  </div>
</app-scaffold>
