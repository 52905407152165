export const DEFAULT_APP_NAME = 'Darex App';
export const DEFAULT_APP_VERSION = '1.0.7';
export const APPLICATION_DEFAULT_LANGUAGE = 'en';

export const CONFIG_YTEMX_APP_THEME = 'ytemxAppTheme';
export const CONFIG_YTEMX_APP_BRANDING = 'ytemxAppBranding';

export const BARTENDER_KEY = 'bartender_'
export const ENTER_SECRET_SETUP_CLICKS_COUNTER = 7;
export const APPLICATION_LANGUAGES =
  [{
    code: 'en',
    name: 'global.english'
  }, {
    code: 'es',
    name: 'global.spanish'
  }, {
    code: 'pt-BR',
    name: 'global.portuguese'
  }, {
    code: 'fr',
    name: 'global.french'
  }, {
    code: 'it',
    name: 'global.italian'
  }];

export const KEYBOARD = {
  KEYBOARD_OPEN_CLASS: 'keyboard-open',
  KEYBOARD_DID_HIDE_EVENT: 'keyboardDidHide',
  KEYBOARD_DID_SHOW_EVENT: 'keyboardDidShow'
};



export const STORAGE_SET_UP_MODEL = 'SetupModel';
export const YTEM_PLATFORM = {
  DEFAULT_CLIENT_ID: 'TRANSPARENCY-APP',
  AUTH: {
    BASE: '/riot-core-services/api/user/login',
    LOGOUT: '/riot-core-services/api/user/logout',
  },
  CONFIGURATION: {
    SETTINGS: '/statemachine-api-configuration/rest/configuration/settings'
  },
  PRODUCT_API: {
    BASE: '/product-api/rest/products',
    VALIDATE_TRANSPARENCY_ID: '/resolver/amazon-transparency',
    TRANSACTION_EVENT: '/statemachine-epcis-events-input-rest/rest/events/publish/amqp',
    AMAZON_TRANSPARENCY_ID: 'https://us-central1-flutter-flow-api.cloudfunctions.net/api/transparency',
    INIT_AMAZON_TRANSPARENCY_ID: 'https://us-central1-flutter-flow-api.cloudfunctions.net/api/transparency/init',
    PRODUCT: '/digital-passport/products/',
    CURSOR: 10000
  },
  SERIALIZATION_API: {
    BASE: '/serialization-api/rest/serialization',
    GTIN_METHOD: '/hexas/sgtin96/gtin/'
  },
  SETTINGS_API: {
    BASE: '/statemachine-api-configuration/rest/configuration/settings/',
    KEY_COMPANY_PREFIX: 'global.company.prefix.list',
    KEY_COMPANY_PREFIX_SEPARATOR: 'global.company.prefix.separator',
    VALUE_COMPANY_PREFIX_SEPARATOR: '-'
  },
  SETTINGS: {
    DEFAULT_COMPANY_PREFIX_LENGTH: 6
  },
  EPCIS_API: {
    BASE: '/epcis-core/rest/events/searches/',
    AMPQ: '/statemachine-epcis-events-input-rest/rest/events/publish/amqp'
  },
  STATUS_API: {
    BASE: '/statemachine-api-status-rest/rest/statuses/epcs/',
    STOCK: '/statemachine-api-status-rest/rest/statuses/location/level/premise/code/',
    STOCK_EXTENSION: '/gtins',
    CURSOR: 500
  },
  LOCATION_API: {
    BASE: '/statemachine-api-configuration/rest/configuration/locations'
  },
  REFERENCE_LIST_API: {
    BASE: '/supply-chain-api/rest/supply_chain/reference_lists/',
    SEARCHES: 'searches'
  }
};

export const DATE_FORMAT = {
  YEAR_MONTH_DAY: 'YYYYMMDD',
  MONTH_DAY_YEAR: 'MMDDYYYY'
}

export const VALIDATION_INPUT_HOST = '^[A-Za-z0-9.:_@-]$';
export const VALIDATION_INPUT_USER = '^[A-Za-z0-9._@-]$';
