import {InterpolatedString} from "./interpolated.string";

export interface LoadingDictionaryConfig {
  backdropDismiss?: boolean,
  lockBackButton?: boolean,
  onDismiss?: () => void
  duration?: number // in ms, -1 or undefined for infinite
  cssClass?: string
}

export class LoadingDictionaryItem {
  constructor(public label: string | InterpolatedString,
              public config: LoadingDictionaryConfig = DEFAULT_LOADING_CONFIG) {
  }
}

/****************************************************
 ********************** DEFAULTS *********************
 ******************************************************/

export const DEFAULT_LOADING_CONFIG: LoadingDictionaryConfig = {
  backdropDismiss: false,
  lockBackButton: true,
  onDismiss: undefined
}

export const DEFAULT_LOADINGS = {
  LOADING: new LoadingDictionaryItem('global.loading'),
  LOADING_DURATION: (durationMs: number) => new LoadingDictionaryItem('global.loading', {duration: durationMs})
}
