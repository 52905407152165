export class BasicUtils {
  static isNullOrUndefinedOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  static isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  static getDateString(date: Date): string {
    try {
      const dateToString = new Date(date);
      return new Date(dateToString.getTime() - (dateToString.getTimezoneOffset() * 60000)).toISOString();
    } catch (e) {
      const dateToString = new Date();
      return new Date(dateToString.getTime() - (dateToString.getTimezoneOffset() * 60000)).toISOString();
    }
  }
}
