import {Injectable} from "@angular/core";
import {State} from "@ngxs/store";
import {HistoryObj, HistoryStateModel} from "./history-state.model";
import {History} from "./history.actions";
import {StateService} from "../state.service";
import {EventManagerService} from "source-ui-commons";
import {DateTimerUtils} from "../../../app/utils/date-timer.utils";
import {DATE_FORMAT} from "../../../app/app.constants";
import {ItemModel, Product} from "../../../app/providers/ytem/model/product.model";

@State<HistoryStateModel>({
  name: 'history',
  defaults: {
    data: []
  }
})

@Injectable()
export class HistoryState {
  constructor(private state: StateService,
              private eventManager: EventManagerService) {
    this.subscribeToEvents();
  }

  subscribeToEvents() {
    this.eventManager.subscribe(History.AddToHistory.getName()).subscribe(this.addToHistory);
    this.eventManager.subscribe(History.CleanHistory.getName()).subscribe(this.cleanHistory);
  }

  addToHistory = (action: History.AddToHistory) => {
    const history = {...this.state.history};
    const data: Array<HistoryObj> = [].concat(history.data.slice(0, 20));
    const dataFound = data.findIndex((dt: HistoryObj) => dt.search === action.search);
    if(dataFound > -1) {
      data[dataFound] = {
        scannedTime: DateTimerUtils.parseDayToStringFormat(new Date(), DATE_FORMAT.MONTH_DAY_YEAR, '/') + ' ' + DateTimerUtils.getTime(new Date(), ':'),
        search: action.search,
        obj: action.obj,
        event: action.event,
        product: action.product,
        item: action.item
      }
    } else {
      data.unshift({
        scannedTime: DateTimerUtils.parseDayToStringFormat(new Date(), DATE_FORMAT.MONTH_DAY_YEAR, '/') + ' ' + DateTimerUtils.getTime(new Date(), ':'),
        search: action.search,
        obj: action.obj,
        event: action.event,
        product: action.product,
        item: action.item
      });
    }
    history.data = data;
    this.state.patchState(history, ['history.data']);
  }

  cleanHistory = (action: History.CleanHistory) => {
    const history = {...this.state.history};
    history.data = [];
    this.state.patchState(history, ['history.data'])
  }
}
