export class ThemeUtils {
  public static mergeValues<T>(newValues: T, defaultValues: T): T {
    const response = {...defaultValues};
    for (let prop in newValues) {
      response[prop] = newValues[prop];
    }
    return response;
  }

}
