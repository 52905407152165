import {YtemBaseService} from '../base/YtemBaseService';
import {YTEM_PLATFORM} from '../../../app.constants';
import {mapTo, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  AmazonTransparencyResponse, AmazonTransparencyResponseData,
  Product,
  ProductResponse,
  ProductResponseModel,
  ValidateTransparencyIDResponse
} from '../model/product.model';

@Injectable()
export class ProductApiService extends YtemBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public validateTransparencyID(id: string): Observable<ValidateTransparencyIDResponse> {
    const path = this.getPath(YTEM_PLATFORM.PRODUCT_API.VALIDATE_TRANSPARENCY_ID);
    return this.http.post<ValidateTransparencyIDResponse>(path, {
      "identifier": id
    }, {headers: this.getHeaders()}).pipe(
      map((res: ValidateTransparencyIDResponse) => res),
    );
  }

  public initAmazonTransparency(): Observable<any> {
    return this.http.get<AmazonTransparencyResponse>(YTEM_PLATFORM.PRODUCT_API.INIT_AMAZON_TRANSPARENCY_ID);
  }
  public amazonTransparency(id: string): Observable<AmazonTransparencyResponse> {
    return this.http.post<AmazonTransparencyResponse>(YTEM_PLATFORM.PRODUCT_API.AMAZON_TRANSPARENCY_ID, {
      transparencyId: id
    }).pipe(
      map((res: AmazonTransparencyResponse) => res),
    );
  }

  public sendTransactionEvent(data: AmazonTransparencyResponseData, transparencyId: string, bizLocation: string): Observable<boolean> {
    const path = this.getPath(YTEM_PLATFORM.PRODUCT_API.TRANSACTION_EVENT);
    return this.http.post<AmazonTransparencyResponse>(path, {
      events: [{
        "type": "TransactionEvent",
        "eventTime": new Date().toISOString(),
        "bizStep": "urn:epcglobal:cbv:bizstep:authenticating",
        "disposition": "urn:epcglobal:cbv:disp:authenticated",
        "readPoint": YTEM_PLATFORM.DEFAULT_CLIENT_ID,
        "bizLocation": bizLocation,
        "bizTransList": [{
          "value": transparencyId
        }],
        "extension": {
          "mjx_user": this.getUser(),
          "amzn_transparency_title": data.title,
          "amzn_transparency_description": JSON.stringify(data.description),
          "amzn_transparency_imageURL": data.image
        },
        "epcList": [
        ],
        "action": "OBSERVE"
      }]
    }, {headers: this.getHeaders()}).pipe(
      map(() => true),
    );
  }

  public findProducts(products: Array<string>): Observable<Array<Product>> {
    const path = this.getPath(YTEM_PLATFORM.PRODUCT_API.BASE);
    return this.http.post<ProductResponse>(path, products, {headers: this.getHeaders()}).pipe(
      map((res: ProductResponse) => res.products),
    );
  }

  public productSearch(product: string): Observable<ProductResponseModel> {
    const path = this.getPath(YTEM_PLATFORM.PRODUCT_API.PRODUCT) + product;
    return this.http.get<ProductResponseModel>(path, {headers: this.getHeaders()}).pipe(
      map((res: ProductResponseModel) => res),
    );
  }

  public saveProductNewData(product: Product, hexa: string): Observable<boolean> {
    const path = this.getPath(YTEM_PLATFORM.PRODUCT_API.PRODUCT) + hexa;
    return this.http.put<ProductResponseModel>(path, product, {headers: this.getHeaders()}).pipe(
      catchError(() => of(true)),
      map(() => true),
    );
  }
}
