import {AnimationController, ModalController, createGesture} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {StateService} from "../state.service";
import {BackButtonService} from "../../../providers/back-button.service";
import {EventManagerService, SheetModalComponent} from "source-ui-commons";
import {BACK_BUTTON_PRIORITY} from "../../../providers/navigation/nav.constants";
import {AnimationUtils} from "../../../utils/animation.utils";
import {Modal} from "./modal.actions";

@Injectable()
export class ModalState {

  private DEFAULTS: any = {
    showBackdrop: true,
    enableBackdropDismiss: false
  };

  private modals: HTMLIonModalElement[] = [];

  constructor(private modalCtrl: ModalController,
              private state: StateService,
              private animCtrl: AnimationController,
              private backButton: BackButtonService,
              private eventManager: EventManagerService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(Modal.ShowModal.getName()).subscribe(this.showModal);
    this.eventManager.subscribe(Modal.ShowSheetModal.getName()).subscribe(this.showSheetModal);
    this.eventManager.subscribe(Modal.HideModals.getName()).subscribe(this.hideModals);
  }

  /**
   * Commands
   */
  showModal = async (action: Modal.ShowModal) => {
    if (this.modals.some(modal => modal.className === action.component.name)) {
      return;
    }
    const enterAnimation = (baseEl: any) => {
      const contentAnimation = this.animCtrl.create()
        .addElement(baseEl.shadowRoot.querySelector('.modal-wrapper'))
        .duration(150)
        .delay(50)
        .easing('ease-out')
        .fromTo('opacity', '0.01', '1');
      const backdropAnimation = this.animCtrl.create()
        .addElement(baseEl.shadowRoot.querySelector('ion-backdrop'))
        .duration(150)
        .easing('ease-out')
        .fromTo('opacity', '0.01', '0.2');
      return backdropAnimation.addAnimation(contentAnimation)
    }

    const leaveAnimation = (baseEl: any) => {
      return enterAnimation(baseEl).direction('reverse');
    }

    const modalOption = {
      ...this.DEFAULTS, ...action.options,
      component: action.component,
      componentProps: {...action.params, dismiss: () => {
        modal.dismiss()
      }},
    }

    if (action.animation) {
      modalOption.enterAnimation = enterAnimation
      modalOption.leaveAnimation = leaveAnimation
    }

    const modal = await this.modalCtrl.create(modalOption);
    const unregisterBackFunc = this.backButton.registerBackButtonAction(() => {
      if (!action.lockBackButton) {
        modal.dismiss();
      } else {
        console.warn('Back button locked by Modal');
      }
    }, BACK_BUTTON_PRIORITY.MODAL);
    modal.onDidDismiss().then((data?: any) => {
      this.removeFromStack(modal);
      if (action.hasOwnProperty("onDismiss") && action.onDismiss != undefined) {
        action.onDismiss((data && data.data) ? data.data : undefined);
      }
    });
    modal.onWillDismiss().then(() => {
      unregisterBackFunc();
    });
    modal.present();
    this.addToStack(modal);
  };

  showSheetModal = (action: Modal.ShowSheetModal) => {
    const animationUtils: AnimationUtils = new AnimationUtils();
    const params = {
      ...action.params,
      modalCtrl: this.modalCtrl,
      createGesture: createGesture,
      animationUtils: animationUtils
    }
    this.showModal(new Modal.ShowModal(SheetModalComponent,
      params,
      {
        cssClass: ['modal-sheet'],
        ...action.options
      },
      (data) => {
        action.onDismiss?.(data);
      },
      false,
      false
    ));
  }

  hideModals = (action: Modal.HideModals) => {
    this.modals.forEach(modal => modal.dismiss());
    this.clearAllFromStack();
  }

  private addToStack(modal: HTMLIonModalElement) {
    this.modals.push(modal);
  }

  private removeFromStack(modal: HTMLIonModalElement) {
    this.modals = this.modals.filter(p => p !== modal);
  }

  private clearAllFromStack() {
    this.modals = [];
  }
}
