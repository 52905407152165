import {ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {YtemService} from '../../providers/ytem/ytem.service';
import {NavController} from '@ionic/angular';
import {EventManagerService} from "source-ui-commons";
import {Toast} from "../../../core/states/toast/toast.actions";
import {AnimationUtils} from "../../../utils/animation.utils";
import {DEFAULT_APP_VERSION, VALIDATION_INPUT_HOST, VALIDATION_INPUT_USER} from "../../app.constants";
import {UiViewModelMediator} from "./services/mediators/ui-view-model.mediator";
import {SubSink} from "subsink";
import {StateService} from "../../../core/states/state.service";
import {delay, map, switchMap, tap} from "rxjs/operators";
import {App} from "../../../core/states/app/app.actions";
import {YtemAuthenticationModel} from "../../providers/ytem/model/ytemSettings";
import {LoginService} from "./services/login.service";
import {Subscription} from "rxjs";
import {Loading} from "../../../core/states/loading/loading.actions";
import {Modal} from "../../../core/states/modal/modal.actions";
import {Theme} from "../../../core/states/theme/theme.actions";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {
  pageName = LoginPage.name;
  public static route = 'login';

  public validationInputHost = VALIDATION_INPUT_HOST;
  public validationInputUser = VALIDATION_INPUT_USER;

  public viewModel = new UiViewModelMediator();

  public loggedIn = false;
  private sub = new SubSink();
  public version = '-'
  public uuid = '-';
  private simulatedTag: string;
  private initSubscription: Subscription;

  animationUtils: AnimationUtils;

  @ViewChild('loginForm') loginForm: ElementRef;

  constructor(private navController: NavController,
              private ytem: YtemService,
              private eventManager: EventManagerService,
              private state: StateService,
              private cdr: ChangeDetectorRef,
              private loginService: LoginService,
              private ngZone: NgZone,
              public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams
      .subscribe(params => {
          if(!!params['resolve']) {
            this.simulatedTag = params['resolve'];
          }
        }
      );
  }

  ngOnInit() {
    this.loggedIn = false;
    this.subscribeEvents();
    this.ngZone.run(() => this.version = DEFAULT_APP_VERSION);
  }

  unsubscribeEvents() {
    this.sub.unsubscribe();
  }

  initFormWithValues(setupModel: YtemAuthenticationModel, autologin = true) {
    this.viewModel.onRememberMeChanged(setupModel.isRememberMeEnabled);
    this.viewModel.onHostChanged(setupModel.host);
    this.viewModel.onPasswordChanged(setupModel.password);
    this.viewModel.onUsernameChanged(setupModel.username);
    if(autologin) {
      setTimeout(() => {
        this.login();
      }, 500)
    }
  }

  subscribeEvents() {
    this.sub.sink = this.eventManager.subscribe(App.AppReady.getName()).pipe(
      delay(2000)
    ).subscribe(() => {
      this.initializeConfigurationFirstTime();
    });
    this.sub.sink = this.eventManager.subscribe(App.AppLogout.getName()).subscribe(() => {
      this.loggedIn = false;
      this.ytem.logout().subscribe();
      this.initializeConfigurationFirstTime(false);
    });
    this.sub.sink = this.state.observeChanges('platform.deviceInfo').pipe(
      map(deviceInfo => deviceInfo ? deviceInfo.uuid : '-')
    ).subscribe({next: uuid => this.ngZone.run(() => this.uuid = uuid || '-')});
  }

  private initializeConfigurationFirstTime(autoLogin = true) {
    this.eventManager.publish(new Loading.ShowLoadingDict());
    this.cdr.detectChanges();
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
    this.openModal();
    this.initSubscription = this.loginService.getConfigurationFromStorage().subscribe((newSetupModel) => {
        this.eventManager.publish(new Loading.HideLoading());
        if(newSetupModel) {
          this.initFormWithValues(newSetupModel, autoLogin);
        } else {
          this.initFormWithValues({isRememberMeEnabled: false, host: '', username: '', password: ''}, false);
        }
        this.cdr.detectChanges();
        console.log("Updated with new values from config file");
      },
      error => {
        this.eventManager.publish(new Loading.HideLoading());
        console.log("Error loading setup model from file or storage", error);
        this.initFormWithValues({isRememberMeEnabled: false, host: '', username: '', password: ''}, false);
      });
  }
  openModal() {
    this.eventManager.publish(new Modal.ShowSheetModal({
      content: this.loginForm
    }, {
      canDismiss: () => Promise.resolve(this.loggedIn)
    }))
  }

  login() {
    if (!this.viewModel.$host.value.trim()) {
      this.eventManager.publish(new Toast.ShowToast({
        position: 'top',
        message: 'Please enter the host name',
        duration: 1000,
      }))
      return;
    }

    if (!this.viewModel.$username.value.trim()) {
      this.eventManager.publish(new Toast.ShowToast({
        duration: 1000,
        message: 'Please enter the username'
      }))
      return;
    }

    if (!this.viewModel.$password.value.trim()) {
      this.eventManager.publish(new Toast.ShowToast({
        duration: 1000,
        message: 'Please enter the password'
      }))
      return;
    }

    this.eventManager.publish(new Loading.ShowLoadingDict());
    if(this.viewModel.$isRememberMeEnabled.value) {
      this.loginService.saveSetupModel({
        host: this.viewModel.$host.value,
        username: this.viewModel.$username.value,
        password: this.viewModel.$password.value,
        isRememberMeEnabled: this.viewModel.$isRememberMeEnabled.value
      })
    } else {
      this.loginService.saveSetupModel({})
    }
    this.ytem.login(this.viewModel.$username.value.trim(), this.viewModel.$password.value.trim(), this.viewModel.$host.value.trim()).pipe(
      switchMap(() => this.eventManager.publishRx(new Theme.UpdateAvailableThemes())),
      tap(() => {
        if(this.state.theme.selectedBrand) {
          this.eventManager.publish(new Theme.SetThemeByBrandName(this.state.theme.selectedBrand));
        }
      }),
      switchMap(() => this.ytem.initAmazonTransparency())
    ).subscribe({
      next: () => {
        this.loggedIn = true;
        this.eventManager.publish(new Modal.HideModals());
        if(!!this.simulatedTag) {
          this.navController.navigateForward('tabs/home', {
            queryParams: {
              resolve: this.simulatedTag
            }
          }).then(() => {
            this.eventManager.publish(new Loading.HideLoading());
          });
        } else {
          this.navController.navigateForward('tabs/home', ).then(() => {
            this.eventManager.publish(new Loading.HideLoading());
          });
        }
      },
      error: err => {
        this.loggedIn = false;
        console.log('login.error', err);
        this.eventManager.publish(new Loading.HideLoading())
        if (err.error && err.error.error) {
          this.eventManager.publish(new Toast.ShowToast({
            message: err.error.error
          }));
        } else {
          this.eventManager.publish(new Toast.ShowToast({
            message: err.message
          }));
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
    this.unsubscribeEvents();
    this.cdr.detach();
  }
}
