import {InterpolatedString} from "./interpolated.string";
import {AlertInput} from "@ionic/core/dist/types/components/alert/alert-interface";

export type AlertButtonRole = 'ok' | 'save' | 'cancel' | 'retry' | 'continue' | 'exit' | 'yes' | 'no' | string;

export class AlertButton {
  constructor(public label: string, public role: AlertButtonRole | string) {
  }
}

export interface AlertDictionaryConfig {
  backdropDismiss?: boolean,
  lockBackButton?: boolean,
  onDismiss?: (data: any) => void,
  onButtonClick?: (btnRole: AlertButtonRole | string) => void, // returns the button's role when clicked
}

export interface AlertDictionaryItemParams {
  title: string | InterpolatedString,
  subtitle?: string | InterpolatedString,
  message: string | InterpolatedString,
  buttons: AlertButton[],
  inputs?: AlertInput[],
  config: AlertDictionaryConfig
}

export class AlertDictionaryItem {
  constructor(public params: AlertDictionaryItemParams) {
  }
}

/****************************************************
 ********************** DEFAULTS *********************
 ******************************************************/

export const DEFAULT_ALERTS_CONFIG: AlertDictionaryConfig = {
  backdropDismiss: true,
  lockBackButton: false,
  onDismiss: undefined,
  onButtonClick: undefined
}

export const DEFAULT_ALERT_BUTTONS = {
  OK: new AlertButton('global.ok', 'ok'),
  CANCEL: new AlertButton('global.cancel', 'cancel'),
  SAVE: new AlertButton('global.save', 'save'),
  SYNC: new AlertButton('global.sync', 'yes'),
  RETRY: new AlertButton('buttons.retry', 'retry'),
  CONTINUE: new AlertButton('buttons.continue', 'continue'),
  EXIT: new AlertButton('menu_tracker_retail.exit', 'exit'),
  EXIT_WITHOUT_SYNC: new AlertButton('buttons.exitWithoutSync', 'exit'),
  YES: new AlertButton('buttons.yes', 'yes'),
  NO: new AlertButton('buttons.no', 'no'),
  CLEAN: new AlertButton('buttons.clean', 'clean'),
  CLEAR: new AlertButton('global.clear', 'clear'),
  DISCARD: new AlertButton('buttons.discard', 'discard')
}

export const DEFAULT_ALERTS = {
  SERVER_ERROR: (buttons?: AlertButton[],
                 config?: AlertDictionaryConfig) =>
    new AlertDictionaryItem({
        title: 'change_status.alerts.server_error.title',
        message: 'change_status.alerts.server_error.message',
        buttons,
        config
      }),
  GENERIC_ERROR: (message: string, title: string, buttons?: AlertButton[],
                 config?: AlertDictionaryConfig) =>
    new AlertDictionaryItem({
      title: title,
      message: message,
      buttons,
      config
    }),
  READER_ERROR: (buttons?: AlertButton[],
                 config?: AlertDictionaryConfig) =>
    new AlertDictionaryItem({
      title: 'change_status.alerts.server_error.title',
      message: 'change_status.alerts.server_error.message',
      buttons,
      config
    }),
  READER_DISCONNECTED_ERROR: (buttons?: AlertButton[],
                              config?: AlertDictionaryConfig) =>
    new AlertDictionaryItem({
      title: 'change_status.alerts.server_error.title',
      message: 'change_status.alerts.server_error.message',
      buttons,
      config
    })
}
