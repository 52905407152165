import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {Drivers} from '@ionic/storage';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {YtemService} from './providers/ytem/ytem.service';
import {EpcisApiService} from './providers/ytem/epcis/epcis-api.service';
import {StatusApiService} from './providers/ytem/status/status-api.service';
import {LocationApiService} from './providers/ytem/location/location-api.service';
import {ProductApiService} from './providers/ytem/product-api/product-api.service';
import {httpInterceptorProviders} from './providers/ytem/interceptor';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {environment} from '../environments/environment';
import {HistoryState} from '../core/states/history/history.state';
import {TabsPageRoutingModule} from './pages/tabs/tabs-routing.module';
import {AuthService} from './providers/ytem/auth/auth.service';
import {EventManagerService, EventsService} from "source-ui-commons";
import {AngularSvgIconModule} from "angular-svg-icon";
import {BackButtonService} from "../providers/back-button.service";
import {LoadingService} from "../providers/loading.service";
import {ModalService} from "../providers/modal.service";
import {StateCoreModules} from "../core/states/state-core.modules";
import {StorageService} from "../services/storage/storage.service";
import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import {IonicStorageModule} from "@ionic/storage-angular";
import {NavService} from "../providers/navigation/nav.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {Diagnostic} from "@awesome-cordova-plugins/diagnostic/ngx";
import {ThemeService} from "../services/theme/theme.service";
import {ProductsCacheService} from "../services/products/cache/products.cache.service";
import {ProductsService} from "../services/products/products.service";
import {SettingsService} from "./components/settings-flow/settings.service";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({swipeBackEnabled: false}),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxsModule.forRoot([
      HistoryState
    ], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(
      {
        key: [
          HistoryState,
        ]
      }
    ),
    IonicStorageModule.forRoot({
      name: 'bartender',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    }),

    AppRoutingModule,
    StateCoreModules,
    TabsPageRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })],
  providers:
    [
      YtemService,
      AuthService,
      EpcisApiService,
      StatusApiService,
      LocationApiService,
      ProductApiService,
      StorageService,
      BackButtonService,
      LoadingService,
      ModalService,
      ThemeService,
      HistoryState,
      NavService,
      ScreenOrientation,
      EventManagerService,
      EventsService,
      Diagnostic,
      ProductsCacheService,
      ProductsService,
      SettingsService,
      httpInterceptorProviders,
      {
        provide: RouteReuseStrategy,
        useClass: IonicRouteStrategy
      }
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
