import {Animation, createAnimation} from "@ionic/angular";

export type CustomAnimation = {
  isRunning: () => boolean,
  stop: () => void,
  play: () => Promise<void>,
}

export type CustomAnimationConfig = {
  nativeElement?: any,
  nativeElementList?: any[],
  beforeStyles?: { [key: string]: string },
  toProperties?: { [key: string]: string },
  keyframes?: {offset: number, styles: { [key: string]: string }}[],
  durationMs?: number,
  delayMs?: number,
  iterations?: number,
  onFinished?: () => void
};

export class AnimationUtils {

  public DURATION_S = 140;
  public DURATION_M = 210;
  public DURATION_L = 400;

  public EASING_CUBIC = 'cubic-bezier(0.4, 0, 0.2, 1)';

  public create(config: CustomAnimationConfig): CustomAnimation {
    let isRunning = true;

    // Required to get initial animation value
    let jElement: any;

    const createElementAnimation = (nativeElement: any): Animation => {
      let animation = createAnimation()
        .addElement(nativeElement)
        .afterStyles(config.toProperties)
        .easing(this.EASING_CUBIC)
        .onFinish(() => {
          isRunning = false;
          animation.stop();
          config.onFinished?.();
        });
      if (config.durationMs !== undefined) {
        animation.duration(config.durationMs);
      } else {
        animation.duration(this.DURATION_M);
      }
      if (config.beforeStyles) {
        animation.beforeStyles(config.beforeStyles)
      }
      if (config.delayMs) {
        animation.delay(config.delayMs);
      }
      if (config.iterations) {
        animation.iterations(config.iterations < 0 ? Infinity : config.iterations);
      }
      if (config.toProperties) {
        Object.keys(config.toProperties).forEach(property => {
          const newValue = config.toProperties[property];
          const currentValue = this.getCurrentPropertyValue(jElement, property, newValue);
          animation = animation.fromTo(property, currentValue, newValue);
        });
      } else if (config.keyframes?.length) {
        animation = animation.keyframes(config.keyframes.map(configKeyFrame => {
          const keyFrame = {offset: configKeyFrame.offset};
          Object.assign(keyFrame, configKeyFrame.styles);
          return keyFrame;
        }));
      }
      return animation;
    }
    if (config.nativeElement) {
      jElement = $(config.nativeElement);
      const animation = createElementAnimation(config.nativeElement);
      return {
        play: () => new Promise<void>((resolve, reject) => {
          isRunning = true;
          animation.play().then(resolve).catch(reject);
        }),
        stop: () => {
          if (isRunning) {
            isRunning = false;
            animation.stop();
            config.onFinished?.();
          }
        },
        isRunning: () => isRunning
      };
    } else if (config.nativeElementList?.length) {
      jElement = $(config.nativeElementList[0]);
      const animations = config.nativeElementList.map(el => createElementAnimation(el))
      const groupAnimation = createAnimation().addAnimation(animations);
      return {
        play: () => new Promise<void>((resolve, reject) => {
          isRunning = true;
          groupAnimation.play().then(resolve).catch(reject);
        }),
        stop: () => {
          if (isRunning) {
            isRunning = false;
            groupAnimation.stop();
            config.onFinished?.();
          }
        },
        isRunning: () => isRunning
      };
    } else {
      return {
        play: () => new Promise<void>((resolve) => {
          resolve()
        }),
        stop: () => {},
        isRunning: () => isRunning
      }
    }

    // jquery implementation:
    // $(nativeElement).animate(toProperties, {duration: durationMs, easing: 'swing', queue: false, complete: () => onFinished()});
  }


  private getCurrentPropertyValue(jElement: any, property: string, requestedNewValue: string) {
    let currentValue = jElement.css(property);
    if (property === 'transform') {
      const oldTransformValues = this.getTransformValues(jElement, currentValue);
      currentValue = `translate(${oldTransformValues.x}%, ${oldTransformValues.y}%) scale(${oldTransformValues.scale})`
    }
    if (currentValue === '0px' && requestedNewValue.endsWith('%')) {
      currentValue = '0%';
    } else if (currentValue === '0%' && requestedNewValue.endsWith('px')) {
      currentValue = '0px';
    }
    return currentValue;
  }

  private getTransformValues(jElement: any, matrixValue: string): { x: number, y: number, scale: number } {
    let matrix = matrixValue.replace(/[^0-9\-.,]/g, '').split(',');
    let scale = Number(matrix[0]);
    let x = Number(matrix[12] || matrix[4]) / jElement.width() * 100;
    let y = Number(matrix[13] || matrix[5]) / jElement.height() * 100;
    return {x, y, scale};
  };
}
