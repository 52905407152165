import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {ProductsClient} from "./client/products.client";
import {catchError, map, tap} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import {ProductsCacheService} from "./cache/products.cache.service";
import {Injectable} from "@angular/core";
import {ProductImageViewport} from "../../app/pipes/product-image/product-image";
import {StateService} from "../../core/states/state.service";
import {Product} from "../../app/providers/ytem/model/product.model";
import {YtemBaseService} from "../../app/providers/ytem/base/YtemBaseService";

@Injectable()
export class ProductsService extends YtemBaseService{
  client: ProductsClient = new ProductsClient(this.http, this.state);

  constructor(private http: HttpClient,
              private state: StateService,
              private sanitizer: DomSanitizer,
              private cache: ProductsCacheService) {
    super()
  }

  getProductImage(product: Product, verifyCache: boolean = false, viewport: ProductImageViewport = 'mobile'): Observable<any> {
    if (this.cache.hasImage(product.gtin, viewport)) {
      return of(this.getProductImageCacheByGtin(product.gtin, viewport));
    } else {
      const productNotFound = this.cache.productsNotFound.some(item => item === product.gtin);
      if (verifyCache && productNotFound) {
        return of(null);
      } else {
        const $defaultProductImage = this.getProductImageByGtin(product.gtin, undefined, undefined, viewport).pipe(
          tap((productImage) => this.cache.saveImage(product.gtin, productImage, viewport)),
          map((productImage) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(productImage))),
          catchError(async (error) => {
            if (error.status === 404) {
              console.log(`IMAGE NOT FOUND GTIN => ${product.gtin}`);
              this.cache.addNotFoundProduct(product.gtin);
            }
          })
        );
        if (product.pictureGeneric && !this.state.platform.deviceInfo.mobileWeb) {
          const $byGenericUrl = this.getProductImageByGenericUrl(product.pictureGeneric).pipe(
            tap((productImage) => this.cache.saveImage(product.gtin, productImage, viewport)),
            map((productImage) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(productImage))));

          return $byGenericUrl.pipe(catchError(() => $defaultProductImage));
        } else {
          return $defaultProductImage;
        }
      }
    }
  }

  getProductImageCacheByGtin(gtin: string, viewport: ProductImageViewport = 'mobile') {
    const productImage = this.cache.getImageCacheByGtin(gtin, viewport);
    return productImage ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(productImage)) : null;
  }

  getProductImageByGtin(gtin: string, useCache: boolean = false, useSanitizer: boolean = false, viewport: ProductImageViewport = 'mobile'): Observable<any> {
    if (useCache && this.cache.hasImage(gtin, viewport)) {
      return of(this.getProductImageCacheByGtin(gtin, viewport));
    }
    const time = new Date().getTime();
    const path = this.getPath(`/product-api/rest/products/${gtin}/picture?time=${time}&viewport=${viewport}`)
    return this.client.getProductImage(this.getHeaders(), path).pipe(
      tap(img => {
        if (useCache) {
          this.cache.saveImage(gtin, img, viewport);
        }
      }),
      map(img => useSanitizer ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(img)) : img)
    );
  }

  getProductImageByGenericUrl(url: string): Observable<any> {
    return this.client.getProductImageByGenericUrl(url);
  }

}
