import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {YtemAuthenticationModel, YtemSettings} from './model/ytemSettings';
import {Observable, of} from 'rxjs';
import {EpcisApiService} from './epcis/epcis-api.service';
import {EpcisEvent, SearchRequestI} from './model/epcis.model';
import {TagExtended} from './model/status.model';
import {StatusApiService} from './status/status-api.service';
import {Injectable} from '@angular/core';
import {ProductApiService} from './product-api/product-api.service';
import {LocationApiService} from './location/location-api.service';
import {
  AmazonTransparencyResponse,
  AmazonTransparencyResponseData,
  Product,
  ProductResponseModel,
  ValidateTransparencyIDResponse
} from './model/product.model';
import {CxLocation, Fixture} from './model/location.model';
import {AuthService} from './auth/auth.service';
import {ThemeService} from "../../../services/theme/theme.service";
import {ProductsService} from "../../../services/products/products.service";
import {SettingsService} from "../../components/settings-flow/settings.service";

@Injectable({
  providedIn: 'root'
})
export class YtemService {

  locations: Map<string, CxLocation> = new Map();

  constructor(private authService: AuthService,
              private epcisApiService: EpcisApiService,
              private statusApiService: StatusApiService,
              private productApiService: ProductApiService,
              private productService: ProductsService,
              private settingsService: SettingsService,
              private locationApiService: LocationApiService,
              private themeApiService: ThemeService) {
  }

  public login(username: string, password: string, host: string): Observable<boolean> {
    const defaultConfig: YtemAuthenticationModel = {
      host: 'https://' + host,
      username: username,
      password: password
    };
    this.authService.init(defaultConfig);
    return this.authService.login(username, password).pipe(
      map((token) => {
        defaultConfig.token = token;
        this.init(defaultConfig);
        return true;
      })
    )
  }

  public init(config: YtemSettings) {
    this.productApiService.init(config);
    this.locationApiService.init(config);
    this.epcisApiService.init(config);
    this.statusApiService.init(config);
    this.themeApiService.init(config);
    this.productService.init(config);
    this.settingsService.init(config);
  }

  public logout(): Observable<any> {
    return this.authService.logout()
  }

  public validateTransparencyID(id: string): Observable<ValidateTransparencyIDResponse> {
    return this.productApiService.validateTransparencyID(id).pipe(
      catchError(() => {
        return of(null);
      })
    )
  }

  public epcisSearch(search: SearchRequestI): Observable<Array<EpcisEvent>> {
    return this.epcisApiService.search(search).pipe(map(res => res.events));
  }

  public productSearch(hexa: string): Observable<ProductResponseModel> {
    return this.productApiService.productSearch(hexa);
  }

  public saveProductNewData(data: AmazonTransparencyResponseData, productInformation: Product, hexa: string): Observable<boolean> {
    productInformation.imageURL = data.image;
    productInformation.vpn = data.title;
    productInformation.modelLabel = JSON.stringify(data.description);
    return this.productApiService.saveProductNewData(productInformation, hexa);
  }

  public amazonTransparency(id: string, bizLocation: string = 'DAREX'): Observable<AmazonTransparencyResponse> {
    let transparencyResponse: AmazonTransparencyResponse = null;
    return this.productApiService.amazonTransparency(id).pipe(
      tap((response) => {
        transparencyResponse = response;
      }),
      switchMap((transparencyResponse) => this.sendTransactionEvent(transparencyResponse.data, id, bizLocation)),
      map(() => transparencyResponse)
    );
  }

  public sendTransactionEvent(data: AmazonTransparencyResponseData, transparencyId: string, bizLocation: string = 'DAREX') {
    return this.productApiService.sendTransactionEvent(data, transparencyId, bizLocation);
  }
  public initAmazonTransparency(): Observable<boolean> {
    return this.productApiService.initAmazonTransparency();
  }

  public statuses(epcs: Array<string>): Observable<Array<TagExtended>> {
    return this.statusApiService.statuses(epcs).pipe(
      filter(data => !!data)
    );
  }

  public completeProducts(events: Array<EpcisEvent>): Observable<EpcisEvent[]> {
    const uniqueGtins: Set<string> = new Set<string>();
    if (events && events.length) {
      events.map(it => {
        if (it.epcList && it.epcList.length) {
          it.epcList.map(obj => {
            if (obj.gtin) {
              uniqueGtins.add(obj.gtin);
            }
          })
        }
      });
    }

    if (uniqueGtins.size) {
      return this.findProducts(Array.from(uniqueGtins)).pipe(
        map((products) => {
          events.map(it => {
            if (it.epcList && it.epcList.length) {
              it.epcList.map(obj => {
                if (obj.gtin) {
                  obj.product = products.find(product => product.gtin === obj.gtin);
                }
              });
            }
          });
          return events;
        }),
        catchError((err) => {
          console.log('error', err);
          return of(events);
        })
      )
    } else {
      return of(events);
    }
  }

  public findProducts(products: Array<string>): Observable<Array<Product>> {
    return this.productApiService.findProducts(products);
  }

  public locationTreeFriendlyNames(level?: string, loadFixtures?: boolean): Observable<Map<string, CxLocation>> {
    return this.locationApiService.locationTreeFriendlyNames(level, loadFixtures).pipe(
      tap((val) => this.locations = val)
    )
  }

  public loadFixtures(premiseCode: string): Observable<Array<Fixture>> {
    return this.locationApiService.loadFixtures(premiseCode);
  }

  public sendEvent(event: EpcisEvent): Observable<boolean> {
    return this.epcisApiService.sendEvent(event);
  }
}
