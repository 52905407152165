import {ModalOptions} from "@ionic/angular";
import {EventModel, SimpleRouterComponent} from "source-ui-commons";

/**
 * Commands
 */

export namespace Modal {
  export class ShowModal extends EventModel {
    override type = '[Modal] Show modal';

    constructor(public component: any,
                public params?: { [key: string]: any },
                public options?: Partial<ModalOptions>,
                public onDismiss?: (data?: any) => void,
                public lockBackButton: boolean = false,
                public animation: boolean = true) {
      super();
    }
  }

  export class ShowSheetModal extends EventModel {
    override type = '[Modal] Show sheet modal';

    constructor(public params?: {
                  content: any,
                  backdropContent?: any,
                  getRouter?: () => SimpleRouterComponent,
                  onBackdropDismiss?: () => void,
                  desiredBreakpoints?: number[],
                  containerStyles?: {[key: string]: string},
                  canDismissGlobal?: boolean
                },
                public options?: Partial<ModalOptions>,
                public onDismiss?: (data?: any) => void) {
      super();
    }
  }

  export class HideModals extends EventModel {
    override type = '[Modal] Hide modals'

    constructor() {
      super();
    }
  }
}
