import {Injectable} from "@angular/core";
import {YtemBaseService} from "../../providers/ytem/base/YtemBaseService";

@Injectable()
export class SettingsService extends YtemBaseService {

  constructor() {
    super()
  }

  getHostUrl() {
    return this.getHost();
  }

}
