import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ThemeClient} from "./client/theme.client";
import {catchError, map} from "rxjs/operators";
import {AppTheme, ThemeBrand, ThemeVariables} from "../../core/states/theme/theme.model";
import {CONFIG_YTEMX_APP_BRANDING, CONFIG_YTEMX_APP_THEME, YTEM_PLATFORM} from "../../app/app.constants";
import {YtemBaseService} from "../../app/providers/ytem/base/YtemBaseService";

export const DEFAULT_THEME_BRAND: ThemeBrand = {
  name: 'global.default',
  variables: {},
  variablesDark: {}
};

export const DEFAULT_THEME_OVERRIDES: AppTheme = {
  variables: {},
  variablesDark: {}
}

@Injectable()
export class ThemeService extends YtemBaseService{
  client: ThemeClient = new ThemeClient(this.http);

  constructor(private http: HttpClient) {
    super();
  }

  getThemeVariables(): Observable<ThemeVariables> {
    const themeConfigsKeys = [
      CONFIG_YTEMX_APP_THEME,
      CONFIG_YTEMX_APP_BRANDING
    ];
    return this.client.getThemeVariables(themeConfigsKeys, this.getPath(YTEM_PLATFORM.CONFIGURATION.SETTINGS), this.getHeaders()).pipe(
      map((data) => {
        let baseThemeOverrides: AppTheme = {
          variables: {},
          variablesDark: {}
        }
        let themeBrands: ThemeBrand[] = [DEFAULT_THEME_BRAND];

        if (data && data.length) {
          // PARSE BRANDS
          const branding = data.find((dt) => dt.key === CONFIG_YTEMX_APP_BRANDING);
          if (branding && branding.value && typeof branding.value === 'string') {
            try {
              themeBrands = JSON.parse(branding.value);
            } catch (e) {
              console.log('Error parsing theme branding');
            }
          }

          // PARSE BASE THEME OVERRIDES
          const theme = data.find((dt) => dt.key === CONFIG_YTEMX_APP_THEME);
          if (theme && theme.value && typeof theme.value === 'string') {
            try {
              baseThemeOverrides.variables = JSON.parse(theme.value)[0]?.variables || {};
              baseThemeOverrides.variablesDark = JSON.parse(theme.value)[0].variablesDark || {};
            } catch (e) {
              console.log('Error parsing theme');
              baseThemeOverrides = DEFAULT_THEME_OVERRIDES;
            }
          } else {
            baseThemeOverrides = DEFAULT_THEME_OVERRIDES;
          }
        }
        return {
          baseThemeOverrides: baseThemeOverrides,
          themeBrands: themeBrands
        } as ThemeVariables;
      }),
      catchError(() => of({
        baseThemeOverrides: DEFAULT_THEME_OVERRIDES,
        themeBrands: [DEFAULT_THEME_BRAND],
        menuOverride: [],
      } as ThemeVariables))
    );
  }
}
