import {NavigationOptions} from "@ionic/angular/common/providers/nav-controller";
import {RxEvent} from "source-ui-commons";

export namespace Nav {
  export class NavSafePushModule extends RxEvent<void> {
    override type = '[Nav] Safe Push Module';

    constructor(public page: string | { route: string }, public params?: any) {
      super();
    }
  }

  export class NavPushRx extends RxEvent<boolean> {
    override type = '[Nav] Push page rx';

    constructor(public page: string | { route: string }, public params?: any, public options?: NavigationOptions) {
      super();
    }
  }

  export class NavPushRootRx extends RxEvent<boolean> {
    override type = '[Nav] Push Root page rx';

    constructor(public page: string | { route: string }, public params?: any, public options?: NavigationOptions) {
      super();
    }
  }

  export class NavReplaceRx extends RxEvent<void> {
    override type = '[Nav] Replace page rx';

    constructor(public page: string | { route: string },
                public params?: any,
                public outPageOptions?: NavigationOptions,
                public inPageOptions?: NavigationOptions) {
      super();
    }
  }

  export class NavPopRx extends RxEvent<boolean> {
    override type = '[Nav] Pop page rx';

    constructor(public options?: NavigationOptions, public route?: string) {
      super();
    }
  }

  export class UnsafeNavPopRx extends RxEvent<boolean> {
    override type = '[Nav] Unsafe Nav Pop Rx';

    constructor(public options?: NavigationOptions) {
      super();
    }
  }

}
