import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import {environment} from "../../environments/environment";
import {applyDecryption, applyEncryption} from "../../utils/encryption.utils";

@Injectable()
export class StorageService {
  constructor(private storage: Storage) {
  }

  public async create(): Promise<any> {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    return this.storage.create();
  }

  public set(key, value): Promise<any> {
    const dataToSave = applyEncryption(JSON.stringify(value));
    return this.storage.set(key, dataToSave);
  }

  public async get(key): Promise<any> {
    const dataEncrypted = await this.storage.get(key);
    if (!dataEncrypted) { // Migration step from indexedDb (ionic storage) to SQLite
      return this.tryGetAndMigrateOldSetting(key);
    }
    let dataDecrypt = dataEncrypted;
    if(environment.production) {
      dataDecrypt = applyDecryption(dataEncrypted);
    }
    if (typeof dataDecrypt === 'string') {
      try {
        return JSON.parse(dataDecrypt);
      }catch (e) {
        return dataDecrypt;
      }
    } else {
      return dataDecrypt;
    }
  }

  public remove(key): Promise<any> {
    return this.storage.remove(key);
  }

  public clear(): Promise<any> {
    return this.storage.clear();
  }

  private async tryGetAndMigrateOldSetting(key: string) {
    return new Promise((resolve) => {
      try {
        const request = window.indexedDB.open("tracker_retail");
        request.onsuccess = (event) => {
          try {
            const db: IDBDatabase = (event.target as any).result;
            const store = db.transaction("_ionickv", "readwrite").objectStore("_ionickv");
            const storeRequest = store.get(key);
            storeRequest.onsuccess = (event) => {
              try {
                const data = (event.target as any).result;
                if (!data) {
                  resolve(undefined);
                  return;
                }
                const dataObj = JSON.parse(applyDecryption(data));
                store.delete(key);
                this.set(key, dataObj); // Execute the actual migration to ionic storage SQLite
                resolve(dataObj);
              } catch (e) {
                resolve(undefined);
              }
            }
            storeRequest.onerror = () => {
              resolve(undefined);
            }
          } catch (e) {
            resolve(undefined);
          }
        };
        request.onerror = (event) => {
          resolve(undefined);
        };
      } catch (e) {
        resolve(undefined);
      }
    }).catch((e) => {
      console.error("Error migrating storage", e);
    });
  }

  public getLocalStorage(key) {
    try {
      return JSON.parse(localStorage.getItem(key))
    }catch (e) {
      return localStorage.getItem(key)
    }
  }

  public removeLocalStorage(key) {
    return localStorage.removeItem(key)
  }
}
