import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {BACK_BUTTON_PRIORITY} from "./navigation/nav.constants";

@Injectable()
export class BackButtonService {
  actions: BackButtonAction[] = [];

  constructor(private platform: Platform) {
    platform.backButton.subscribeWithPriority(BACK_BUTTON_PRIORITY.TOP, details => this.emitBackButton(details));
  }

  registerBackButtonAction(action: () => void, priority = BACK_BUTTON_PRIORITY.MIN): () => void {
    const timestamp = Date.now();
    this.actions.push({
      id: timestamp,
      priority: priority,
      action: action
    });
    return () => {
      this.unregisterId(timestamp);
    };
  }

  private emitBackButton(details) {
    if (this.actions && this.actions.length) {
      this.actions.reduce((prev, current) => {
        if (current.priority > prev.priority) {
          return current;
        } else if (current.priority === prev.priority) {
          if (current.id > prev.id) {
            return current;
          } else {
            return prev;
          }
        } else {
          return prev;
        }
      }).action();
    }
  }

  private unregisterId(id) {
    this.actions = this.actions.filter(e => e.id !== id);
  }
}

export interface BackButtonAction {
  id: number;
  priority: number;
  action: () => void;
}
