import {ThemeVariables, ZoomLevel} from "./theme.model";
import {EventModel, RxEvent} from "source-ui-commons";

export namespace Theme {
  export class SetThemeByBrandName extends EventModel {
    override type = '[Theme] Set theme by brand name';
    constructor(public brandName: string) {
      super();
    }
  }

  export class ResetTheme extends EventModel {
    override type = '[Theme] Reset theme';
  }

  export class UpdateAvailableThemes extends RxEvent<ThemeVariables> {
    override type = '[Theme] Update Available Themes';
  }

  export class ReduceZoomLevel extends EventModel {
    override type = '[Theme] Reduce Zoom Level';
  }

  export class IncreaseZoomLevel extends EventModel {
    override type = '[Theme] Increase Zoom Level';
  }

  export class SetZoomLevel extends EventModel {
    override type = '[Theme] Set Zoom level';
    constructor(public zoomLevel: ZoomLevel) {
      super();
    }
  }
}
