import {LoadingOptions} from "@ionic/angular";
import {DEFAULT_LOADINGS, LoadingDictionaryItem} from "../../dictionary/loading.dictionary";
import {EventModel} from "source-ui-commons";


export namespace Loading {
  export class ShowLoading extends EventModel{
    override type = '[Loading] Show loading';

    constructor(public options?: LoadingOptions, public blockBackButton: boolean = true) {
      super();
    }
  }

  export class ShowLoadingDict extends EventModel{
    override type = '[Loading] Show loading using dictionary';

    constructor(public loading: LoadingDictionaryItem = DEFAULT_LOADINGS.LOADING) {
      super();
    }
  }

  export class HideLoading extends EventModel{
    override type = '[Loading] Hide loading';
  }
}
