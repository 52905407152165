import {Injectable} from "@angular/core";
import {from, Observable, of, retry, switchMap, throwError, zip} from "rxjs";
import {EventManagerService} from "source-ui-commons";
import {App} from "./app.actions";
import {LoginPage} from "../../../app/pages/login/login.page";
import {catchError, delay, map, tap} from "rxjs/operators";
import {PlatformNamespace} from "../platform/platform.actions";
import {Loading} from "../loading/loading.actions";
import {Modal} from "../modal/modal.actions";
import {Alert} from "../alert/alert.actions";
import {Nav} from "../nav/nav.actions";
import {KeyboardNameSpace} from "../keyboard/keyboard.actions";
import {Platform} from "@ionic/angular";
import {Device} from "@capacitor/device";
import {Diagnostic} from "@awesome-cordova-plugins/diagnostic/ngx";
import {LOGIN_DICTIONARY} from "../../../app/pages/login/login.dictionary";
import {History} from "../history/history.actions";

@Injectable()

export class AppState {

  private permissions = [
    this.diagnostic.permission.CAMERA
  ];

  constructor(private eventManager: EventManagerService,
              private diagnostic: Diagnostic,
              private platform: Platform) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribeRx(App.InitializeApp.getName(), this.initializeApp);
    this.eventManager.subscribeRx(App.LogoutApp.getName(), this.logoutApp);
  }

  hasAllPermissions(): Observable<boolean> {
    if (!this.platform.is("capacitor")) return of(true).pipe(delay(1000));
    if (this.platform.is("ios")) return of(true).pipe(delay(1000));
    return from(this.diagnostic.getPermissionsAuthorizationStatus(this.permissions)).pipe( map((result) => {
        return Object.values(result).every(res => res === this.diagnostic.permissionStatus.GRANTED)
      }),
      catchError((e) => {
        console.log('PERMISSIONS ERROR!!!', e)
        return throwError(e)
      }));
  }

  requestPermissions(): Observable<any> {
    if (!this.platform.is("capacitor")) return of(true);
    return from(Device.getInfo()).pipe(
      switchMap(() => this.diagnostic.requestRuntimePermissions(this.permissions)),
    );
  }

  initializeApp = (action: App.InitializeApp): Observable<any> => {
    const showPermissionsAlert = (): Promise<void> => {
      return new Promise<void>((resolve) => {
        this.eventManager.publish(new Alert.HideAlerts());
        this.eventManager.publish(new Loading.HideLoading());
        this.eventManager.publish(new Alert.ShowAlertDict(LOGIN_DICTIONARY.ALERTS.USER_PERMISSIONS_ERROR(
          () => resolve()
        )));
      });
    }
    return this.hasAllPermissions().pipe(
      switchMap((hasAllPermissions) => {
        if (hasAllPermissions) {
          return of(true);
        } else {
          return from(showPermissionsAlert()).pipe(
            switchMap(() => this.requestPermissions()),
            switchMap(() => this.hasAllPermissions()),
            tap((hasAllPermissions) => {
              if (!hasAllPermissions) {
                throw new Error("User did not accept all permissions, retrying!")
              }
            }),
            retry(5) // This is what retries until user accepts all permissions
          )
        }
      }),
      catchError(() => of(true)),
      switchMap(() => this.eventManager.publishRx(new PlatformNamespace.InitializeDeviceInfo())),
      tap(() => this.eventManager.publish(new App.AppReady()))
    );
  }

  logoutApp = (action: App.LogoutApp): Observable<any> => {
    this.eventManager.publish(new History.CleanHistory());
    this.eventManager.publish(new Loading.HideLoading());
    this.eventManager.publish(new Modal.HideModals());
    this.eventManager.publish(new Alert.HideAlerts());
    this.eventManager.publish(new KeyboardNameSpace.HideKeyboard());
    return this.eventManager.publishRx(new Nav.NavPushRootRx(LoginPage)).pipe(
      tap(() => {
        this.eventManager.publish(new App.AppLogout());
      })
    );
  }
}
