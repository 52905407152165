import {ToastButton, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {EventManagerService} from "source-ui-commons";
import {TOAST_PRIORITY_DEFAULT} from "../../dictionary/toast.dictionary";
import {Toast} from "./toast.actions";

@Injectable()
export class ToastState {

  private queue: Toast.ShowToast[] = [];
  current: HTMLIonToastElement;
  currentToastAction: Toast.ShowToast;

  constructor(private toastCtrl: ToastController,
              private translate: TranslateService,
              private eventManager: EventManagerService) {
    this.subscribeToEvents();
  }

  public subscribeToEvents() {
    this.eventManager.subscribe(Toast.ShowToast.getName()).subscribe(this.showToast);
    this.eventManager.subscribe(Toast.ShowToastDict.getName()).pipe(
      map((action: Toast.ShowToastDict) => new Toast.ShowToast({
        message: typeof action.toast.label === 'string'
          ? this.translate.instant(action.toast.label)
          : this.translate.instant(action.toast.label.label, action.toast.label.params),
        duration: action.toast.config.duration,
        cssClass: action.toast.config.cssClass,
        position: action.toast.config.position,
        buttons: action.toast.config.buttons?.map(btn => <ToastButton>{
          text: this.translate.instant(btn.label),
          role: btn.role,
          handler: () => action.toast.config.onButtonClick?.call(this, btn.role)
        })
      }, action.toast.config.createOrUpdateExisting, action.toast.config.priority || TOAST_PRIORITY_DEFAULT))
    ).subscribe(this.showToast);
  }

  showToast = (action: Toast.ShowToast) => {
    if (!this.currentToastAction) {
      this.presentToast(action);
    } else if(action.createOrUpdateExisting) {
      if (action.priority >= this.currentToastAction.priority) {
        this.currentToastAction = action;
        if (this.current) {
          this.current.message = action.options.message;
        }
      }
    } else {
      this.addToQueue(action);
    }
  };

  private addToQueue(action: Toast.ShowToast) {
    this.queue.push(action);
  }

  private async presentToast(action: Toast.ShowToast) {
    if (this.current) {
      this.current.message = action.options.message;
      return;
    }
    action.options.duration = action.options.duration || 1000;
    action.options.position = action.options.position || 'top';
    this.current = await this.toastCtrl.create(action.options);
    this.current.onWillDismiss().then(() => {
      this.current = undefined;
    });
    return this.current.present();
  }

  private hideToast() {
    if (this.current) {
      this.current.dismiss();
    }
  }

  private presentPendingToasts() {
    if (this.queue && this.queue.length) {
      const pendingToast = this.queue[0];
      this.presentToast(pendingToast);
    }
  }
}
