import {Injectable} from "@angular/core";
import {ModalController, ModalOptions} from "@ionic/angular";
import {BackButtonService} from './back-button.service';
import {BasicUtils} from "../app/utils/basic.utils";
import {BACK_BUTTON_PRIORITY} from "./navigation/nav.constants";

@Injectable()
export class ModalService {

  unregisterBackFunc: any;
  modal: HTMLIonModalElement | undefined;

  constructor(private modalCtrl: ModalController,
              private backButtonService: BackButtonService) {

  }

  public async showModal(params: ModalOptions, dismissAction ?: Function): Promise<void> {
    this.modal = await this.modalCtrl.create(params);
    this.unregisterBackFunc = this.backButtonService.registerBackButtonAction(() => {
      if (BasicUtils.isNullOrUndefinedOrEmpty(params.backdropDismiss) || params.backdropDismiss) {
        this.hideModal();
      } else {
        console.warn('Back button locked by Modal');
      }
    }, BACK_BUTTON_PRIORITY.MODAL);

    this.modal.onDidDismiss().then((data) => {
      if (!!dismissAction) {
        dismissAction(data);
      }
      this.unsubscribeBackButton();
    })

    return this.modal.present();
  }

  private unsubscribeBackButton() {
    if (this.unregisterBackFunc) {
      this.unregisterBackFunc();
    }
  }

  public hideModal(data ?: any) {
    this.unsubscribeBackButton();
    // @ts-ignore
    this.modal.dismiss(data).then();
  }

}
